import ActionType from './ActionType';
import { ICapability, IGetProject, IGetProjectPath } from '../model/IGetProject';
import { IGetDataset } from '../model/IGetDataset';

export const getHasModelResults = (projectId: string) => ({
  type: ActionType.GET_HAS_MODEL_RESULTS,
  data: projectId
})

export const setHasModelResults = (hasModelResults = true) => ({
  type: ActionType.SET_HAS_MODEL_RESULTS,
  data: hasModelResults
})

export const deleteOutputFolder = () => ({
  type: ActionType.DELETE_OUTPUT_FOLDER
})

export const downloadResults = (fileExtension: string) => ({
  type: ActionType.DOWNLOAD_MODEL_RESULTS,
  data: fileExtension
})

export const getProjectContent = (project: IGetProject | IGetProjectPath, filter = "") => ({
  type: ActionType.GET_PROJECTCONTENT,
  data: {project: project, filter: filter }
})

export const getProjectAndPath = (projectId: string) => ({
  type: ActionType.GET_PROJECTANDPATH,
  data: projectId
})

export const setFilter = (filter: string) => ({
  type: ActionType.FILTER_SET,
  data: filter
})

export const setLoadingProjectContent = (loading = true) => ({
  type: ActionType.LOADING_PROJECTCONTENT,
  data: loading
})

export const setLoadingProject = (loading = true) => ({
  type: ActionType.LOADING_PROJECT,
  data: loading
})

export const setProject = (project: IGetProject | null, projectPath: Array<IGetProjectPath>) => ({
  type: ActionType.SET_PROJECT,
  data: { project, projectPath }
})

export const setProjectContent = 
  (projectContent: Array<IGetDataset | IGetProject>, 
    project: IGetProject | null, parent: IGetProject | null, 
    path: IGetProjectPath[],
    capabilities: ICapability | null) => ({
  type: ActionType.SET_PROJECTCONTENT,
  data: { projectContent: projectContent, project: project, parent: parent, path: path, capabilities: capabilities }
})

export const setProjectContentDialogOpen = (open: boolean, selectable: boolean) => ({
  type: ActionType.OPEN_PROJECTCONTENT_DIALOG,
  data: { open: open, selectable: selectable }
})

export const setProjectContentPage = (page: number) => ({
  type: ActionType.SET_PROJECTCONTENT_PAGE,
  data: page
})

export const setProjectContentRowsPerPage = (rowsPerPage: number) => ({
  type: ActionType.SET_PROJECTCONTENT_ROWSPERPAGE,
  data: rowsPerPage
})

export const setProjectContentOrder = (order: 'asc' | 'desc') => ({
  type: ActionType.SET_PROJECTCONTENT_ORDER,
  data: order
})

export const setProjectContentOrderBy = (orderBy: string | ((item: any) => string | number)) => ({
  type: ActionType.SET_PROJECTCONTENT_ORDERBY,
  data: orderBy
})