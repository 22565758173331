import { put, takeEvery, take, select, call, all } from 'redux-saga/effects'
import { intl } from '../index'
import { removeUpload, uploadFile } from '../actions/uploads'
import { importFile, updateFile } from '../actions/imports'
import { addError } from '../actions/errors'
import ActionType from '../actions/ActionType'
import { IGetProject } from '../model/IGetProject'
import asIfReaderWriter from '../model/asIfReaderWriter'
import uniqid from 'uniqid'
import { delProjectDataset, getProjectDatasets, getSASToken, updateDataset } from '../apis/metadataApi'
import { IGetDataset } from '../model/IGetDataset'
import { DATASETS, FASTWAVECONFIG, IMPORTS, JOBS, MESH, READER } from '../shared/constants'
import { Feature } from 'geojson'
import { fastWaveConfigSet, setupsSet, showProjectionSystemDialog, uploadPointsUrlSet } from '../actions/mapContent'
import { getDrawnOutlineFiles, getFastwaveConfigFile } from '../helpers/fastwave'
import {  getFastWaveConfig, getProject } from '../reducers/state'
import { downloadSetupToProject, getSetups, processContainer, saveJobStatus } from '../apis/backendApi'
import { dateTimeFormat } from '../helpers/fixTime'
import { setJobId } from '../actions/job'
import { ITrackProgressItem } from '../model/ITrackProgressItem'
import { removeProgressItem, updateOrAddProgressItem } from '../actions/progressItems'
import { IFastWaveConfig, IJob } from '../model/IFastWaveConfig'
import { loadingAOI, loadingOutline, loadingShoreline, pendingImportSet, resetAutoMesh } from '../actions/createMesh'
import { addMessage } from '../actions/message'
import { ISetup } from '../model/ISetup'
import { EVENT_STARTED, EVENT_STARTED_DESC } from '../shared/matomoEvents'
import { getNiceJobName } from '../helpers/jobs'
import { deleteOutputFolder } from '../actions/projectContent'
/* import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { MESH_LAYER_ID } from '../reducers/legend';
import vtkPointPicker from 'vtk.js/Sources/Rendering/Core/PointPicker';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import MikeVisualizerUtil from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerUtil';
import { toRoundedNumber } from '../helpers/utils' */

export default function* watchUploadAndImport() { 
  yield takeEvery(ActionType.POINT_UPDATE, savePointsToConfig)
  yield takeEvery(ActionType.MESH_UPLOAD, saveMesh)
  yield takeEvery(ActionType.OUTLINE_UPLOAD, saveOutline)
  yield takeEvery(ActionType.BATHYMETRY_UPLOAD, saveBathymetry)
  yield takeEvery(ActionType.SETUP_UPLOAD, saveSetup)
  yield takeEvery(ActionType.CONFIG_LAYER_SET, updateOrCreateConfig)
  yield takeEvery(ActionType.UPDATE_FILE, updateConfig)
  yield takeEvery(ActionType.JOBS_SAVE, updateOrCreateJobConfig)
  yield takeEvery(ActionType.POINTS_UPLOAD, handleUploadXY)
  yield takeEvery(ActionType.DRAWN_AREA_UPLOAD, saveDrawnArea)
}

function* updateConfig(action){
  const {id, fileUrl, size, startContainer} = action.data
  const project : IGetProject | null = yield select(getProject);
  try{

    const sasToken = yield call(getSASToken, project.id, id)
    yield call(updateDataset, {datasets: [{
      Id: id, 
      LastModified: new Date(Date.now()).toISOString(), 
      Size: size, 
      SASToken: sasToken.data,
      Url: fileUrl}]}, sasToken.data)   
      
    if (startContainer !== ""){  
      if (startContainer.toString() === JOBS.TESTSETUP ||  startContainer.toString() === JOBS.AUTOSETUP){
        const config = yield select(getFastWaveConfig)
        const datasets = yield call(getProjectDatasets, project.id) 
        const dataset = datasets.find((ds: IGetDataset) => ds.name === config.setup)
        if (dataset === undefined){
          yield call(downloadSetupToProject, project.id, config.setup)
        }
      }                
      const response = yield call(processContainer, project.id, startContainer.toString())
      if (response) {
        const name = getNiceJobName(startContainer.toString())
        yield put(setJobId(startContainer.toString(),response, dateTimeFormat(Date.now()), name, project.id))
      }
    }
    
    const progressItem: ITrackProgressItem = {
      id: action.data.id,
      title: 'Saving completed',
      progressValue: 100,
      done: true
    }
    yield put(updateOrAddProgressItem(progressItem))   
    yield put(removeProgressItem(progressItem))
    
  }
  catch (error) {
    yield put(addError(error)); 
  }
}

export function* saveDrawnArea(action) {
  const {featureCollection, datasetType, epsg} = action.data
  if (featureCollection && datasetType && epsg){    
   
    const project : IGetProject | null = yield select(getProject); 
    if (project){  
      const projectId = project.id  
      if (!project.capabilities.canUpdateContent){
        yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
      }
      else{
        if (datasetType === DATASETS.OUTLINE){
          try{
            const datasets = yield call(getProjectDatasets, projectId)
            const drawnOutlines = getDrawnOutlineFiles(datasets)
            if (drawnOutlines && drawnOutlines.length > 0){
              yield all(drawnOutlines.map(drawnOutlines => call(delProjectDataset, drawnOutlines.id)))
            }            
          }
          catch (error){
            console.log(error)
          }
        }

        if (datasetType === DATASETS.OUTLINE){
          yield put(loadingOutline(true))
        }
        else if (datasetType === DATASETS.AREAOFINTEREST){
          yield put(loadingAOI(true))
        }
        else if (datasetType === DATASETS.OWN_SHORELINE){
          yield put(loadingShoreline(true))
        }

        const id = uniqid.time() 
        const importData =  {
          datasetType,
          reader: "GeoJsonReader",
          writer: "GISWriter",
          coordinateSystemId: epsg,
          properties: {FASTWAVE: datasetType},
          name: datasetType,
          description: "Drawn in FWE"
        }

        try {
          yield put(uploadFile(id, datasetType, featureCollection, false))
  
          while (true) {           
            const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
  
            if (uploadAction.data.id === id) {
              const project : IGetProject | null = yield select(getProject);
              if (project && project.id && project.id !== projectId){
                break;
              }
              if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
                yield put(
                  importFile(
                    id,
                    datasetType,
                    uploadAction.data.fileUrl,
                    projectId,
                    importData,
                    datasetType
                  )
                )
              }
              else if (uploadAction.type === ActionType.UPLOAD_FAIL){
                if (datasetType === DATASETS.OUTLINE){
                  yield put(loadingOutline(false))
                }
                else if (datasetType === DATASETS.AREAOFINTEREST){
                  yield put(loadingAOI(false))
                }
                else if (datasetType === DATASETS.OWN_SHORELINE){
                  yield put(loadingShoreline(false))
                }
              }
              break
            }
          }
        } catch (error) {
          yield put(addError(error))
        }
      }        
    }      
  }
  
}

export function* updateOrCreateConfig(action) {
  const {fastWaveConfig, store, startContainer} = action.data
  if (fastWaveConfig && store){    
    const project : IGetProject | null = yield select(getProject); 
    if (project){ 
      const projectId = project.id  
      if (!project.capabilities.canUpdateContent){
        yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'}))); 
      }
      else{
        const datasets = yield call(getProjectDatasets, projectId)
        const fastwaveConfigDataset: IGetDataset | undefined = getFastwaveConfigFile(datasets) 
        if (fastwaveConfigDataset !== undefined){
          const id = fastwaveConfigDataset.id
          try {
            yield put(uploadFile(id, FASTWAVECONFIG, fastWaveConfig, false, true))
    
            while (true) {             
              const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
    
              if (uploadAction.data.id === id) {
                const project : IGetProject | null = yield select(getProject);
                if (project && project.id && project.id !== projectId){
                  break;
                }
                if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
                  yield put(
                    updateFile(
                      id,
                      FASTWAVECONFIG,
                      uploadAction.data.fileUrl,
                      uploadAction.data.size,
                      startContainer
                    )
                  )
                }
                break
              }
            }
          } catch (error) {
            yield put(addError(error))
          }
        }
        else
        {
          const id = uniqid.time()              
  
          const importData =  {
            name: FASTWAVECONFIG,
            reader: asIfReaderWriter.reader,
            writer: asIfReaderWriter.writer,
            properties: {FASTWAVE: DATASETS.CONFIG}
          }

          let updatedConfig: IFastWaveConfig = fastWaveConfig 
          try{
            const setupResponse = yield call(getSetups)
            const genericSetups = setupResponse ? setupResponse.setups : []
            if (genericSetups && genericSetups.length > 0){
              const genericSetupsNames = genericSetups.map((setup: ISetup) => setup.name)
              updatedConfig = {...updatedConfig, setup: genericSetupsNames[genericSetupsNames.length - 1]} 
              const customSetupDatasets = datasets.filter((d: IGetDataset) => d.name.endsWith(".sw") && !genericSetupsNames.includes(d.name))
              const customSetups = customSetupDatasets.map((ds: IGetDataset) => {return {datasetId: ds.id, name: ds.name, displayName: ds.name, description: ds.description, isCustom: true}})
              yield put(setupsSet(genericSetups.concat(customSetups)))             
            }            
          }
          catch (error) {
            console.log(error)
          }
    
          try {  
            yield put(uploadFile(id, FASTWAVECONFIG, updatedConfig, false))
    
            while (true) {              
              const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
    
              if (uploadAction.data.id === id) {
                const project : IGetProject | null = yield select(getProject);
                if (project && project.id && project.id !== projectId){
                  break;
                }
                if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
                  yield put(
                    importFile(
                      id,
                      FASTWAVECONFIG,
                      uploadAction.data.fileUrl,
                      projectId,
                      importData,
                      IMPORTS.CONFIG,
                      startContainer
                    )
                  )
                }
                break
              }
            }            
            
           
          } catch (error) {
            yield put(addError(error))
          }
          try{
            if (window._paq){
              yield call(window._paq.push, ['trackEvent', EVENT_STARTED, EVENT_STARTED_DESC + "in project " + projectId]);
            }            
          }catch (error) {
            console.log(error)
          }
          yield put(fastWaveConfigSet(updatedConfig, false))
        }        
      }      
    }
  }
}

export function* updateOrCreateJobConfig(action) {
  const jobsConfig = action.data
  if (jobsConfig ){    
    const project : IGetProject | null = yield select(getProject); 
    if (project){  
      const projectId = project.id   
      if (!project.capabilities.canUpdateContent){
        yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
      }
      else{
        const lastJobStatus: IJob = jobsConfig[jobsConfig.length -1]
        const lastStatus =  {partitionKey: projectId,jobId: lastJobStatus.jobId, rowKey: lastJobStatus.rowKey, status: lastJobStatus.status, start: lastJobStatus.start}
        try{
          yield call(saveJobStatus, lastStatus)   
        }
        catch(error){
          console.log(error)
        }
      }      
    }
  }
}

function* saveSetup(action){
  const project : IGetProject | null = yield select(getProject); 
  
  if (project){  
    const projectId = project.id
    if (!project.capabilities.canUpdateContent){
      yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
    }
    else{
      const id = uniqid.time()
     
      const {  data, fileName } = action.data
        
      const importData =  {
        name: fileName,
        reader: asIfReaderWriter.reader,
        writer: asIfReaderWriter.writer
      }
  
      try {
        yield put(uploadFile(id, fileName, data))
  
        while (true) {          

          const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
          
          if (uploadAction.data.id === id) {
            const project : IGetProject | null = yield select(getProject);
            if (project && project.id && project.id !== projectId){
              break;
            }
            if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
              yield put(
                importFile(
                  id,
                  fileName,
                  uploadAction.data.fileUrl,
                  projectId,
                  importData,
                  IMPORTS.SETUP
                )
              )
            }
            break
          }
        }
      } catch (error) {
        yield put(addError(error))
      }
    }
  }
}

function* handleUploadXY(action) {
    
    const id = uniqid.time()
    
    const {  data, fileName, projectId } = action.data

    try {
      yield put(uploadFile(id, fileName, data))

      while (true) { 
        const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])

        if (uploadAction.data.id === id) {
          const project : IGetProject | null = yield select(getProject);
          if (project && project.id && project.id !== projectId){
            break;
          }
          if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
            yield put(
              uploadPointsUrlSet(uploadAction.data.fileUrl)
            )
            yield put(removeUpload(id))
            yield put(removeProgressItem({id, title: "", progressValue: 0}))
          }
          break
        }
      }
    } catch (error) {
      yield put(addError(error))
    }
      
}

function* saveBathymetry(action){
  const project : IGetProject | null = yield select(getProject); 
  
  if (project){  
    const projectId = project.id
    if (!project.capabilities.canUpdateContent){
      yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
    }
    else{
      const id = uniqid.time()
      const {  data, fileName } = action.data
      /* const reader = fileName.endsWith(".xyz") ? "XyzReader": fileName.endsWith(".vti") ? "VtiReader" : fileName.includes(".asc") ? "AsciiReader" : "FileReader"
      const writer = fileName.endsWith(".xyz") ? "VtuWriter": fileName.endsWith(".vti") ? "VtiWriter" : fileName.includes(".asc") ? "VtiWriter" : "FileWriter" */
      const reader = "XyzReader"
      const writer = "VtuWriter"
      const importData = 
      {
        name: fileName,
        reader,
        writer
      } 
  
      try {
        yield put(uploadFile(id, fileName, data))
  
        while (true) {  
          const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
  
          if (uploadAction.data.id === id) {
            const project : IGetProject | null = yield select(getProject);
            if (project && project.id && project.id !== projectId){
              break;
            }
            if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
              
                yield put(pendingImportSet({
                  id,
                  fileName,
                  fileUrl: uploadAction.data.fileUrl,
                  projectId,
                  importData,
                  importType: READER.BATHYMETRY
                }))
                yield put(showProjectionSystemDialog(true, READER.BATHYMETRY))
              
              
            }
            break
          }
        }
      } catch (error) {
        yield put(addError(error))
      }
    }
  }
}

function* saveOutline(action) {
  const project : IGetProject | null = yield select(getProject); 
  
  if (project){  
    const projectId = project.id
    if (!project.capabilities.canUpdateContent){
      yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
    }
    else{      
      yield put(resetAutoMesh())
      yield put(deleteOutputFolder())
      const id = uniqid.time()
      const {  data, fileName, importType } = action.data
      
      const importData = fileName.endsWith("." + MESH) ? {
        name: fileName,
        reader: "MeshFileReader",
        writer: asIfReaderWriter.writer
      } : {
        name: fileName,
        reader: "ShpReader",
        writer: "GISWriter"
      }
  
      try {
        yield put(uploadFile(id, fileName, data))
  
        while (true) { 
          const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL]) 
          if (uploadAction.data.id === id) {
            const project : IGetProject | null = yield select(getProject);
            if (project && project.id && project.id !== projectId){
              break;
            }
            if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
              if (fileName.endsWith("." + MESH)){
                yield put(
                  importFile(
                    id,
                    fileName,
                    uploadAction.data.fileUrl,
                    projectId,
                    importData,
                    IMPORTS.OUTLINE
                  )
              )
              }
              else{
                yield put(pendingImportSet({
                  id,
                  fileName,
                  fileUrl: uploadAction.data.fileUrl,
                  projectId,
                  importData,
                  importType
                }))
                yield put(showProjectionSystemDialog(true, READER.ZIPPEDSHAPE))
              }
              
            }           
            break
          }
        }
      } catch (error) {
        yield put(addError(error))
      }
    }
  }
}

function* saveMesh(action) {
  const project : IGetProject | null = yield select(getProject); 
  
  if (project){  
    const projectId = project.id
    if (!project.capabilities.canUpdateContent){
      yield put(addMessage(intl.formatMessage({id: 'project.noWriteAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'})));  
    }
    else{
      const id = uniqid.time()
      const {  data, fileName } = action.data

      const importData =  {
        name: fileName,
        reader: "MeshFileReader",
        writer: asIfReaderWriter.writer       
      }
  
      try {
        yield put(uploadFile(id, fileName, data))
  
        while (true) {   
          const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
  
          if (uploadAction.data.id === id) {
            const project : IGetProject | null = yield select(getProject);
            if (project && project.id && project.id !== projectId){
              break;
            }
            if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {
              yield put(
                importFile(
                  id,
                  fileName,
                  uploadAction.data.fileUrl,
                  projectId,
                  importData,
                  IMPORTS.MESH
                )
              )
            }
            break
          }
        }
      } catch (error) {
        yield put(addError(error))
      }
    }
  }
}

export function* savePointsToConfig(action){
  const config = yield select(getFastWaveConfig)
  const features = action.data
  if (features){   
    const points = features.map((feat: Feature, index: number) => {
      const geometry = feat.geometry as any
      const coords = geometry.coordinates          
      return  {name: feat.id ? feat.id : "Point" + (index + 1), x: coords[0], y: coords[1] }
    })
    yield put(fastWaveConfigSet({...config, output_points: points}, true))
  }
}

/*     const { getState } = MikeVisualizerLib;
    const { renderer, dataMap } = getState();
    const actor = renderer.getActors().find((a) => a.getActorId() === MESH_LAYER_ID);
    if (actor){
      const elevation = yield select(getElevationName)
      const elevations = MikeVisualizerUtil.getDataValues(MESH_LAYER_ID, elevation)
      const pointPicker = vtkPointPicker.newInstance();
      pointPicker.setPickFromList(1);
      pointPicker.initializePickList();
      pointPicker.addPickList(actor);
      pointPicker.setTolerance(0.001);
      const enrichedPoint = points.map((point) => {        
        let elevation = undefined;        
        const viewPoint = dataMap.getPixelFromCoordinate([point.x, point.y]);
        // const viewPoint = renderer.worldToNormalizedDisplay(point.x, point.y, 0, aspectRatio)
        const y = 171 + viewPoint[1]
        pointPicker.pick([viewPoint[0], 171 + viewPoint[1], 0.0], renderer)
        if (pointPicker.getActors().length > 0) {
          const id: number = pointPicker.getPointId(); 
          elevation = elevations[id]         
          return {...point, name: point.name + " (" + toRoundedNumber(elevation, 2) +")"}
        }
        return point
      })
    

    yield put(fastWaveConfigSet({...config, output_points: enrichedPoint}, true))
  }
  else{ 
    yield put(fastWaveConfigSet({...config, output_points: points}, true))
  }      
  }
} */
