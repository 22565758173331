import React, { useCallback, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ICreateMeshDataset } from '../../reducers/createMesh';
import { iconStyle, tableCellButtonStyle, tableRowButtonStyle } from '../TableComponents/renderers';
import MikeDialog from '../DialogComponents/MikeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVtkItems } from '../../actions/createMesh';
import { dateTimeRender, itemNameRender } from '../Project/projectContentColumns';
import { DATASETS } from '../../shared/constants';
import { useIntl } from 'react-intl';
import { IState } from '../../reducers';
import { cancelDrawnGeometry, uploadDrawnGeometry } from '../../actions/mapContent';
import { OWN_SHORELINE_ID } from '../Viewer';

interface IProps { 
  data: Array<ICreateMeshDataset >;
  canUpdateContent: boolean;
  selectedItems?: Array<ICreateMeshDataset>;  
  onItemClick?: (item:  ICreateMeshDataset ) => void;
  onSelectionChange?: (selectedItems: Array<ICreateMeshDataset>) => void;
  datasetType: string;
}

interface IDeletion {
 message: string;
 itemsToDelete: Array<ICreateMeshDataset>;
}

const dataColumns =[
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
  },
  {
    field: 'itemType',
    label: 'Type'
  },
  {
    field: 'updated',
    label: 'Edited on',
    render: dateTimeRender   
  }
] 
const ShorelineTable = (props: IProps) => {
  const { 
    data,   
    canUpdateContent,
    selectedItems, 
    onItemClick,
    onSelectionChange
  } = props;
  
  const dispatch = useDispatch();
  const intl = useIntl();

  const drawnGeometry = useSelector((state: IState) => state.createMesh.drawnGeometry); 

  const [order, setOrder] = useState<{order: 'asc' | 'desc', orderBy: string | ((item: ICreateMeshDataset) => string | number)}>({order: 'asc', orderBy: 'name'})
  const [confirmDeletion, setConfirmDeletion] = useState<IDeletion | null>(null);
  
  const onHandleRequestSort =  (
    orderBy: string | ((item: ICreateMeshDataset) => string | number),
    order: 'asc' | 'desc'
  ) => {setOrder({order: order, orderBy: orderBy})}
  
  const deleteActionClicked = (row: ICreateMeshDataset) => {  
    setConfirmDeletion({message: intl.formatMessage({id: 'warnings.deleteItem'}), itemsToDelete: [row]})
  }

  
  const renderDeleteItem = useCallback((_value: any, row: ICreateMeshDataset) => {   
    return  (
      <div className={tableRowButtonStyle(!canUpdateContent)}>
        <Delete className={iconStyle(!canUpdateContent)} />        
      </div>
    ) // row && row.name !== NOAA_SHORELINE ?: null
  }, [canUpdateContent])  
 

  const columns = [...dataColumns, 
    {
      field: 'delete',
      label: '',
      render: renderDeleteItem,
      callback: deleteActionClicked,
      className: tableCellButtonStyle
    },    
  ]

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setConfirmDeletion(null);
    }
  }

  const handleOnCancelDrawnGeometry = useCallback((_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      dispatch(cancelDrawnGeometry(OWN_SHORELINE_ID))
    }
  }, [dispatch])

  const submitDrawnGeometry = useCallback(() => {   
    dispatch(uploadDrawnGeometry(DATASETS.OWN_SHORELINE, drawnGeometry))
  }, [dispatch, drawnGeometry])

  const deleteData = useCallback(() => {
    confirmDeletion && confirmDeletion.itemsToDelete && dispatch(deleteVtkItems(confirmDeletion.itemsToDelete, DATASETS.OWN_SHORELINE))
    setConfirmDeletion(null);
  }, [confirmDeletion, dispatch])

  return (
    <>
     <MikeDialog 
        open={confirmDeletion !== null} 
        onCancel={handleOnCancel} 
        onOk={deleteData}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.resetMesh'})}
        message={confirmDeletion ? confirmDeletion.message : ''}    
      />  

      <MikeDialog 
        open={drawnGeometry !== null} 
        onCancel={handleOnCancelDrawnGeometry} 
        onOk={submitDrawnGeometry}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.submitDrawnGeometry'})}
        message={''}    
      />  
    
      <DataTable        
        loading={false}
        columns={columns}
        idField="id"   
        data={data}
        selectedRows={selectedItems ? selectedItems : new Array<ICreateMeshDataset>()}
        onCellClick={onItemClick ? onItemClick : () => {}}      
        onSelectionChange={onSelectionChange ? onSelectionChange : () => {}}
        onHandleRequestSort={onHandleRequestSort}
        orderBy={order.orderBy}
        order={order.order}   
        selectable={true}
        topOffset={0} 
      />
    </>
  );
};

export default ShorelineTable;
