import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { MikeButton } from '@mike/mike-shared-frontend';
import { isNumeric } from '@mike/mike-shared-frontend/mike-shared-helpers/helpers';
import Typography from '@material-ui/core/Typography'
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MikeDialogTitle from '@mike/mike-shared-frontend/mike-project-explorer/DialogComponents/MikeDialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { css } from 'emotion'
import theme, { IMikeTheme } from '../../shared/mikeSharedTheme'
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { filterProjectionSystems, getProjectionSystemsById, pointsTransform, showProjectionSystemDialog } from '../../actions/mapContent';
import { dialogActionsStyle, fieldsetWidthStyle, radioStandardStyle, spinnerContainerStyle } from '../../shared/styles';
import DialogActions from '@material-ui/core/DialogActions';
import MikeProjectionSelect from '../mike-projection-select';
import IProjection from '../mike-projection-select/model/IProjection';
import CircularProgress from '@material-ui/core/CircularProgress';
import { defineMessages, useIntl } from 'react-intl';
import { READER } from '../../shared/constants';
import { importFile } from '../../actions/imports';
import { pendingImportSet } from '../../actions/createMesh';
import { removeProgressItem } from '../../actions/progressItems';
import xyzReaderParameterTranslations from './xyzReaderParameterTranslations';
import ContextHelp from '../ContextHelp';
import { makeStyles, createStyles } from '@material-ui/core'

const titleStyle = css`
  padding: ${theme.spacing(1)}px;
  z-index: 11;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  height: ${theme.spacing(8)}px;
  color: ${theme.palette.background.paper};
  width: 100%;
`;

const dialogContentStyle = css`
  padding-top: 0;
  background-color: ${theme.palette.background.default}; 
  
`;

const contentContainerStyle = css`
   padding: ${theme.spacing(1)}px;  
`
const labelStyle = css`
   padding-top: ${theme.spacing(2)}px;
   padding-bottom: ${theme.spacing(2)}px;
`
const verticalDistanceStyle= css`
  padding-top: ${theme.spacing(2)}px;
`
const titleContainer = css`
  display: flex;
`
const xyzTitleStyle = css`
  padding-top: ${theme.spacing(1)}px;
  padding-bottom: ${theme.spacing(0)}px;
`
const subTitleStyle = css` 
  &.MuiTypography-body1 {
    font-size: 0.75rem;
    &.MuiTypography-root {
      color: ${theme.palette.darkGrey.main};
    }
  }
`

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({  
    radioGroup: {    
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main
      }
    },
  })
})

interface IProps {
  epsgCode: number;   
}
const ProjectionSystemDialog = (props: IProps) => {
  const { epsgCode } = props;
  const dispatch = useDispatch();
  const intl = useIntl();  
  const classes = styles();

  const type: READER = useSelector(
    (state: IState) => state.mapContent.projectionSystemDialogForReaderType
  );  

  const messages = useMemo(() => {
    switch (type){
      case READER.XY: {
        return defineMessages({
          title: { id: 'projectionSystemDialog.xyReader.title' },
          question: { id: 'projectionSystemDialog.xyReader.question' },
          yes: { id: 'projectionSystemDialog.xyReader.yes' },
          no: { id: 'projectionSystemDialog.xyReader.no' },         
        })
      }
      case READER.ZIPPEDSHAPE: {
        return defineMessages({
          title: { id: 'projectionSystemDialog.zippedShapeReader.title' },
          question: { id: 'projectionSystemDialog.zippedShapeReader.question' },
          yes: { id: 'projectionSystemDialog.zippedShapeReader.yes' },
          no: { id: 'projectionSystemDialog.zippedShapeReader.no' },         
        })
      }
      case READER.BATHYMETRY: {
        return defineMessages({
          title: { id: 'projectionSystemDialog.bathymetryReader.title' },
          question: { id: 'projectionSystemDialog.bathymetryReader.question' },
          yes: { id: 'projectionSystemDialog.bathymetryReader.yes' },
          no: { id: 'projectionSystemDialog.bathymetryReader.no' },         
        })
      }
    }
  }, [type]) 

  const isOpen: boolean = useSelector(
    (state: IState) => state.mapContent.showProjectionSystemDialog
  );  

  const pendingImport  = useSelector(
    (state: IState) => state.createMesh.pendingImport
  );  

  const uploadingPoints = useSelector(
    (state: IState) => state.mapContent.uploadingPoints
  );  

  const coordSystems: Array<IProjection> = useSelector(
    (state: IState) => state.mapContent.selectedProjectionSystems
  );  
 
  const searchProjectionSystemsById: boolean = useSelector(
    (state: IState) => state.mapContent.searchProjectionSystemsById
  );  

  const loadingCoordSystems = useSelector(
    (state: IState) => state.mapContent.loadingCoordinateSystems
  );  

  const [selectedEPSG, setSelectedEPSG] = useState(epsgCode)

  useEffect(() => {
    setSelectedEPSG(epsgCode)   
  }, [epsgCode]); 

  const handleCancel = useCallback((_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      if (type !== READER.XY && pendingImport){
        dispatch(pendingImportSet(null))
        dispatch(removeProgressItem({id: pendingImport.id, title: "", progressValue: 0}))
      }      
      dispatch(showProjectionSystemDialog(false))
  }
  }, [dispatch, type, pendingImport])

  const [hasTargetSRID, setHasTargetSRID] = useState(true)
  const [xyzColumnOption, setXyzColumnOption] = useState('')

  const handleChange = (event: { target: { value: string }; }) => {   
    setHasTargetSRID(event.target.value === 'true')
  }

  const handleChangeXYZOption= (event: { target: { value: string }; }) => {   
    setXyzColumnOption(event.target.value)
  }

  const onProjectionSearchTextChanged = (searchText: string) => {
    isNumeric(searchText) ?
      dispatch(getProjectionSystemsById(Number(searchText))) :
      dispatch(filterProjectionSystems(searchText))
  };

  const onProjectionSelected = (epsgCode: number) => {
    setSelectedEPSG(epsgCode)
  }

  const onOk = useCallback(() => {
    switch (type){
      case READER.XY: {
        dispatch(pointsTransform(hasTargetSRID ? epsgCode : selectedEPSG))
        break;
      }
      case READER.BATHYMETRY:{       
        const { id, fileName, fileUrl, projectId, importData, importType } = pendingImport  
        const xyzParameter = {...importData, xyzColumnSequence: xyzColumnOption}
        dispatch(importFile(
          id,
          fileName,
          fileUrl,
          projectId,
          hasTargetSRID ? xyzParameter : {...xyzParameter, coordinateSystemId: selectedEPSG},
          importType
        ))
        break;
      }
      case READER.ZIPPEDSHAPE: {
        const { id, fileName, fileUrl, projectId, importData, importType } = pendingImport       
        dispatch(importFile(
          id,
          fileName,
          fileUrl,
          projectId,
          hasTargetSRID ? importData : {...importData, coordinateSystemId: selectedEPSG},
          importType
        ))
        break;
      }
      
    }
    dispatch(showProjectionSystemDialog(false))
   
  }, [type, dispatch, hasTargetSRID, epsgCode, selectedEPSG, pendingImport, xyzColumnOption])

  const getOKButtonIsEnabled = useCallback(() => {
    const projectionDefined = hasTargetSRID || epsgCode !== selectedEPSG
    if (type === READER.BATHYMETRY){
      if (projectionDefined){
        return xyzColumnOption !== undefined
      }
    }   
    return projectionDefined
  }, [hasTargetSRID, epsgCode, selectedEPSG, type, xyzColumnOption] )

  return (
    <Dialog maxWidth={'sm'} onClose={handleCancel} open={isOpen} >
      <MuiDialogTitle className={titleStyle}>
        <MikeDialogTitle title={intl.formatMessage(messages.title)} onClose={handleCancel} />
      </MuiDialogTitle>
      <MuiDialogContent className={dialogContentStyle}>
        <div className={contentContainerStyle}> 
          <div className={titleContainer}>
            <Typography className={labelStyle} variant="body2">{intl.formatMessage(messages.question)}</Typography>
            <ContextHelp helpTexts={[intl.formatMessage({id: 'projectionSystemDialog.pickProjection'})]}/>
          </div>
          
          <FormControl component="fieldset" className={fieldsetWidthStyle}>
            <RadioGroup aria-label="fileformat" name="fileformat" value={hasTargetSRID} onChange={handleChange} className={classes.radioGroup}> 
              <FormControlLabel value={true} control={<Radio color="primary" className={radioStandardStyle} />} label={intl.formatMessage(messages.yes)} />               
              <FormControlLabel value={false} control={<Radio color="primary" className={radioStandardStyle} />} label={intl.formatMessage(messages.no)} /> 
              {loadingCoordSystems ? <div className={spinnerContainerStyle}><CircularProgress/></div>:
               <MikeProjectionSelect
               
                onProjectionSelected={onProjectionSelected}
                onProjectionSearchTextChanged={onProjectionSearchTextChanged}
                projections={coordSystems}
                projectionsLoading={loadingCoordSystems}
                searchById={searchProjectionSystemsById}
              />}
          </RadioGroup>
        </FormControl>
          {type === READER.BATHYMETRY && 
            <div className={verticalDistanceStyle}>
              <div className={titleContainer}>
                <div>
                  <Typography
                    variant="body2"
                    className={xyzTitleStyle}
                  >
                    {xyzReaderParameterTranslations.title}
                  </Typography>
                  <Typography className={subTitleStyle}>
                    {xyzReaderParameterTranslations.subTitle}
                  </Typography>
                </div>
                <ContextHelp helpTexts={[xyzReaderParameterTranslations.info]}/>
              </div>
              <FormControl component="fieldset">
                <RadioGroup aria-label="fileformat" name="fileformat" value={xyzColumnOption} onChange={handleChangeXYZOption} className={classes.radioGroup}> 
                  <FormControlLabel value={'xycz'} control={<Radio color="primary" className={radioStandardStyle} />} label={xyzReaderParameterTranslations.xyczOption} />               
                  <FormControlLabel value={'xyzc'} control={<Radio color="primary" className={radioStandardStyle} />} label={xyzReaderParameterTranslations.xyzcOption} /> 
                  <FormControlLabel value={'xyz'} control={<Radio color="primary" className={radioStandardStyle} />} label={xyzReaderParameterTranslations.xyzOption} />  
              </RadioGroup>
            </FormControl>
            </div>
          }        
        </div>
    </MuiDialogContent>
    <DialogActions className={dialogActionsStyle}>
        <MikeButton onClick={handleCancel} buttontype="text">
          {"Cancel"}
        </MikeButton>
        <MikeButton active={uploadingPoints} onClick={onOk} buttontype="primary" disabled={!getOKButtonIsEnabled()}>
          {"Upload"}
        </MikeButton>
      </DialogActions>
  </Dialog>
  );
};

export default ProjectionSystemDialog;