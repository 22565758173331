import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../reducers'
import { useMatch, useParams } from 'react-router-dom'
import { Dialog, DialogActions } from '@material-ui/core';
import MikeButton from './../mike-button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MikeDialogTitle from './../DialogComponents/MikeDialogTitle';
import { areaOfInterestUploadedOrSelected, bathymetryDatasetsUploadedOrSelected,  loadMesh,  loadPoints, outlineUploaded, setSelectedMesh, shorelineUploadedOrSelected } from '../../actions/mapContent'
import { makeStyles, createStyles } from '@material-ui/core'
import theme, { IMikeTheme } from '../../shared/mikeSharedTheme'
import Sidebar from '../Sidebar'
import Viewer from '../Viewer'
import MikeProjectExplorer from './../MikeProjectExplorer';
import { getSortedPageRows } from './../TableComponents/support'
import { IGetProject, IGetProjectPath } from '../../model/IGetProject';
import { IGetDataset } from '../../model/IGetDataset';
import { 
  getProjectContent, 
  setProjectContentDialogOpen, 
  getProjectAndPath,
  setProjectContent,
  setProjectContentPage, 
  setProjectContentRowsPerPage,
  setProjectContentOrder,
  setProjectContentOrderBy,
  deleteOutputFolder
} from '../../actions/projectContent'
import { ICapability } from '../../model/IGetProject';
import { DATASETS, MESH } from '../../shared/constants';
import { firstTableCellRender, projectContentColumns } from './projectContentColumns';
import { css } from 'emotion';
import TestRunTable from '../TestRunTable';
import SuccessDialog from '../SuccessDialog';
import ProjectionSystemDialog from '../ProjectionSystemDialog';

import { copyDatasetToOtherProject, copyDatasetsToOtherProject } from '../../actions/imports';
import { useIntl } from 'react-intl';
import CellInfo from '../Viewer/CellInfo';
import { resetAutoMesh } from '../../actions/createMesh';

const dialogPaperStyle = css`
  width: 75vw;
  height: 75vh;
  min-height: 75vh;
  max-width: 75vw;
`;

const titleStyle = css`
  padding: ${theme.spacing(1)}px;
  z-index: 11;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  height: ${theme.spacing(8)}px;
  color: ${theme.palette.background.paper};
  width: 100%;
`;

const dialogContentStyle = css`
  padding-top: 0;
  background-color: ${theme.palette.background.default};
`;

const dialogActionsStyle = css`
  padding-right: ${theme.spacing(3)}px;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.background.paper};
  width: 100%;
  z-index: 11;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);
`;

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({
    container: {display: 'flex'}
  })
 })

const Project  = () => { 
  const dispatch = useDispatch(); 
  const intl = useIntl();
  const classes = styles();
  const matchSetup = useMatch('/project/:id/setup'); 

  const { id } = useParams();
 
  // Wwhen url's projectId changes  we need to clean the map
  // and fetch respective project and its path
  useEffect(() => {
    if (id){  
      dispatch(getProjectAndPath(id))      
    }
  }, [dispatch, id]);

  const attributeName: string = useSelector(
    (state: IState) => state.mapContent.elevationName
  );  

  const projectContentDialogIsOpen: boolean = useSelector(
    (state: IState) => state.projectContent.projectContentDialogIsOpen
  );  
 
  const loadingProjectContent: boolean = useSelector(
    (state: IState) => state.projectContent.loadingProjectContent
  );  

  const currentNavigationProject: IGetProject | null =  useSelector(
    (state: IState) => state.projectContent.currentNavigationProject
  ); 

  const currentNavigationProjectPath: IGetProjectPath[] =  useSelector(
    (state: IState) => state.projectContent.currentNavigationProjectPath
  );  

  const currentNavigationProjectCapabilities: ICapability | null =  useSelector(
    (state: IState) => state.projectContent.currentNavigationProjectCapability
  ); 

  const projectContent: Array<IGetProject | IGetDataset>=  useSelector(
    (state: IState) => state.projectContent.projectContent
  ); 

  const projectContentDialogIsSelectable: boolean =  useSelector(
    (state: IState) => state.projectContent.projectContentDialogIsSelectable
  );  

  const page: number =  useSelector(
    (state: IState) => state.projectContent.page
  );  

  const rowsPerPage: number =  useSelector(
    (state: IState) => state.projectContent.rowsPerPage
  );  

  const order: 'asc' | 'desc' =  useSelector(
    (state: IState) => state.projectContent.order
  );  

  const orderBy: string | ((item: any) => string | number) =  useSelector(
    (state: IState) => state.projectContent.orderBy
  );  

  const filter: string =  useSelector(
    (state: IState) => state.projectContent.filter
  );   

  const proj4String = useSelector(
    (state: IState) => state.mapContent.proj4String
  );   

  const epsg = useSelector(
    (state: IState) => state.mapContent.epsgCode
  ); 

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [selItems, setSelItems] = useState(new Array<IGetDataset>());
  
  useEffect(() => {
    // If the dialog is in export mode (not selectable) 
    // we need to check if current folder can create content
    const canCreateContent = currentNavigationProjectCapabilities && currentNavigationProjectCapabilities.canCreateContent
    if (!projectContentDialogIsSelectable){
      setButtonDisabled(!canCreateContent)
    } 
  }, [currentNavigationProjectCapabilities, projectContentDialogIsSelectable]);

  const DATASETTYPE = 'datasetType'

  const onItemClick = useCallback((selectedItem: IGetProject | IGetProjectPath | IGetDataset) => {
    // We skip onItemClick if a dataset has been clicked (only datasets do not have datasetType)
    const isDataset = (DATASETTYPE in selectedItem)
    if (!isDataset){
      const item: IGetProject | IGetProjectPath = selectedItem as IGetProject | IGetProjectPath
      dispatch(setProjectContentPage(0)) 
      dispatch(getProjectContent(item, filter))
    }
    
  }, [dispatch, filter])

  const onOk = useCallback(() => {  
    dispatch(setProjectContentDialogOpen(false, false));
    const autoMeshNeedsToBeReset = [DATASETS.OUTLINE, DATASETS.AREAOFINTEREST, DATASETS.OWN_SHORELINE].includes(filter)
    if (autoMeshNeedsToBeReset){
      dispatch(resetAutoMesh());
      dispatch(deleteOutputFolder())
    }
    const lastselectedItem = selItems.length > 0 ? selItems[selItems.length - 1] : null  
    switch (filter){
      case MESH: {
        if (lastselectedItem){
          if (id !== lastselectedItem.projectId){ 
            dispatch(copyDatasetToOtherProject(id, lastselectedItem, MESH ))
          }
          else{
            dispatch(loadMesh(lastselectedItem.id))
            dispatch(setSelectedMesh(lastselectedItem, id))
          }
        }       
        
        break;
      }
      case DATASETS.POINTS: {
        if (lastselectedItem){          
          dispatch(loadPoints(lastselectedItem));          
        }
        break;
      }
      case DATASETS.OUTLINE: {
        if (lastselectedItem){
          if (id !== lastselectedItem.projectId){           
            dispatch(copyDatasetToOtherProject(id, lastselectedItem,  DATASETS.OUTLINE ))
          }
          else{
            dispatch(outlineUploaded(lastselectedItem.id))  
          }         
        }
            
        break;
      }
      case DATASETS.AREAOFINTEREST: {
        // ToDo: Backend currently support one AOI only
        /* if (selItems.length > 0){   
          const datasetsInTargetProject = selItems.filter((item: IGetDataset) => item.projectId === id )
          if (datasetsInTargetProject.length > 0){
            dispatch(areaOfInterestUploadedOrSelected(datasetsInTargetProject.map((item: IGetDataset) => item.id)));
          }
          const datasetsOutsideTargetProject = selItems.filter((item: IGetDataset) => item.projectId !== id )
          if (datasetsOutsideTargetProject.length > 0){
            dispatch(copyDatasetsToOtherProject(id, datasetsOutsideTargetProject, DATASETS.AREAOFINTEREST));
          }
        } */ 
        if (lastselectedItem){          
          if (id !== lastselectedItem.projectId){
            dispatch(copyDatasetsToOtherProject(id, [lastselectedItem], DATASETS.AREAOFINTEREST));
          }
          else{
            dispatch(areaOfInterestUploadedOrSelected([lastselectedItem.id]));
          }
        }
        break;
      }
      case DATASETS.OWN_SHORELINE: {
        if (lastselectedItem){          
          if (id !== lastselectedItem.projectId){
            dispatch(copyDatasetsToOtherProject(id, [lastselectedItem], DATASETS.OWN_SHORELINE));
          }
          else{
            dispatch(shorelineUploadedOrSelected(lastselectedItem.id));
          }
        }
        break;
      }
      case DATASETS.BATHYMETRY: {
        if (selItems.length > 0){   
          const datasetsInTargetProject = selItems.filter((item: IGetDataset) => item.projectId === id )
          if (datasetsInTargetProject.length > 0){
            dispatch(bathymetryDatasetsUploadedOrSelected(datasetsInTargetProject.map((item: IGetDataset) => item.id)));
          }
          const datasetsOutsideTargetProject = selItems.filter((item: IGetDataset) => item.projectId !== id )
          if (datasetsOutsideTargetProject.length > 0){
            dispatch(copyDatasetsToOtherProject(id, datasetsOutsideTargetProject, DATASETS.BATHYMETRY));
          }
        } 
        break;
      }
    } 
    setSelItems(new Array<IGetDataset>())  
  }, [dispatch, filter, id, selItems])

  const onCancel = useCallback((_event?, reason?) => {   
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setSelItems(new Array<IGetDataset>())  
      dispatch(setProjectContent(Array<IGetDataset | IGetProject>(), null, null, Array<IGetProjectPath>(), null))
      dispatch(setProjectContentDialogOpen(false, false))
    }
  }, [dispatch])

  
  const onSelecteditemsChanged = useCallback((selectedItems: Array<IGetDataset>) => {
    setSelItems(selectedItems)
    projectContentDialogIsSelectable && setButtonDisabled(selectedItems.length === 0)  
  }, [projectContentDialogIsSelectable])

  // If we use v1 endpoints we have to do pagination, odering and filtering client-side
  // Currently we cannot switch to v2 endpoints as there is no endpoint 
  // to get the whole project content (datasets and folders) by one call
  const [ pageRows, setPageRows ] = useState(Array<IGetProject | IGetDataset>())
  
  const onHandleRequestSort = useCallback((orderBy: string | ((item: any) => string | number), order: 'asc' | 'desc') => {  
    dispatch(setProjectContentPage(0))
    dispatch(setProjectContentOrder(order))
    dispatch(setProjectContentOrderBy(orderBy))
  }, [dispatch])

  const onHandleChangePage = useCallback((page: number) => { 
    dispatch(setProjectContentPage(page))
  }, [dispatch])
 
  const onHandleChangeRowsPerPage = useCallback((rowsPerPage: number) => {     
    dispatch(setProjectContentRowsPerPage(rowsPerPage))
  }, [dispatch])


  useEffect(() => {
    setPageRows(getSortedPageRows(projectContent, orderBy, order, page, rowsPerPage))
  }, [projectContent, orderBy, order, page, rowsPerPage]); 


  const projectExplorerTitle = useMemo(() => {
    switch (filter){
      case MESH:
        return intl.formatMessage({id: 'projectExplorer.title.selectMesh'});
      case DATASETS.OUTLINE:
        return intl.formatMessage({id: 'projectExplorer.title.selectMeshOutline'});
      case DATASETS.AREAOFINTEREST:
        return intl.formatMessage({id: 'projectExplorer.title.selectAOI'});
      case DATASETS.BOUNDARYCONDITION:
        return intl.formatMessage({id: 'projectExplorer.title.selectExtraction'});
      case DATASETS.BATHYMETRY:
        return intl.formatMessage({id: 'projectExplorer.title.selectBathymetry'});
      case DATASETS.OWN_SHORELINE:
        return intl.formatMessage({id: 'projectExplorer.title.selectShoreline'});
      default:
         return intl.formatMessage({id: 'projectExplorer.title.selectPoints'});
    }
  }, [filter, intl]) 

  const okButtonLabel = intl.formatMessage({id: 'projectExplorer.okButtonLabel'});

  return (
   <div className={classes.container}>
    {matchSetup && <TestRunTable/>} 
    <SuccessDialog/> 
    <ProjectionSystemDialog epsgCode={epsg}/>    
    <Dialog maxWidth={'lg'} onClose={onCancel} open={projectContentDialogIsOpen} classes={{ paper: dialogPaperStyle }}>
      <MuiDialogTitle className={titleStyle}>
        <MikeDialogTitle title={projectExplorerTitle} onClose={onCancel} />
      </MuiDialogTitle>
      <MuiDialogContent className={dialogContentStyle}>
        <MikeProjectExplorer 
          customFirstTableCellRender={filter !== DATASETS.BOUNDARYCONDITION && firstTableCellRender}
          columns={filter !== DATASETS.BOUNDARYCONDITION && projectContentColumns}  
          onChangePage={onHandleChangePage}
          onChangeRowsPerPage={onHandleChangeRowsPerPage}
          onHandleRequestSort={onHandleRequestSort}
          onItemClick={onItemClick}
          onSelectionChange={onSelecteditemsChanged}
          order={order}
          orderBy={orderBy}
          selectedItems={selItems}       
          loading={loadingProjectContent} 
          page={page}
          pagination={projectContent.length > 10}          
          project={currentNavigationProject !== null ? currentNavigationProject : undefined}        
          projectContent={pageRows}
          projectPath={currentNavigationProjectPath}
          rowsPerPage={rowsPerPage}
          selectable={projectContentDialogIsSelectable}
          totalCount={projectContent.length}
        />
        </MuiDialogContent>
        <DialogActions className={dialogActionsStyle}>
          <MikeButton onClick={onCancel} buttontype="text">
            {intl.formatMessage({id: 'projectExplorer.cancelButtonLabel'})}
          </MikeButton>
          <MikeButton onClick={onOk} buttontype="primary" disabled={buttonDisabled}>
            {okButtonLabel}
          </MikeButton>
        </DialogActions>
      </Dialog>       
      <Sidebar/>
      {attributeName && <CellInfo />}     
      {proj4String  && epsg && <Viewer />}     
    </div>
  )
}

export default Project