import React, { useCallback, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as Arrow } from '@mike/mike-shared-frontend/media/icons/Arrow'
import { GEBCO_BATHYMETRY, ICreateMeshDataset } from '../../reducers/createMesh';
import { iconStyle, tableCellButtonStyle, tableRowButtonStyle } from '../TableComponents/renderers';
import MikeDialog from '../DialogComponents/MikeDialog';
import { useDispatch } from 'react-redux';
import { deleteVtkItems } from '../../actions/createMesh';
import { dateTimeRender, itemNameRender } from '../Project/projectContentColumns';
import { setBathymetryDatasets } from '../../actions/mapContent';
import { DATASETS } from '../../shared/constants';
import { useIntl } from 'react-intl';

interface IProps { 
  data: Array<ICreateMeshDataset >;
  canUpdateContent: boolean;
  selectedItems?: Array<ICreateMeshDataset>;  
  onItemClick?: (item:  ICreateMeshDataset ) => void;
  onSelectionChange?: (selectedItems: Array<ICreateMeshDataset>) => void;
  datasetType: string;
}

interface IDeletion {
 message: string;
 itemsToDelete: Array<ICreateMeshDataset>;
}

const dataColumns =[
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
  },
  {
    field: 'itemType',
    label: 'Type'
  },
  {
    field: 'updated',
    label: 'Edited on',
    render: dateTimeRender   
  }
] 
const BathymetryTable = (props: IProps) => {
  const { 
    data,   
    canUpdateContent,
    selectedItems, 
    onItemClick,
    onSelectionChange
  } = props;
  
  const dispatch = useDispatch();
  const intl = useIntl();

  const [order, setOrder] = useState<{order: 'asc' | 'desc', orderBy: string | ((item: ICreateMeshDataset) => string | number)}>({order: 'asc', orderBy: 'name'})
  const [confirmDeletion, setConfirmDeletion] = useState<IDeletion | null>(null);
  
  const onHandleRequestSort =  (
    orderBy: string | ((item: ICreateMeshDataset) => string | number),
    order: 'asc' | 'desc'
  ) => {setOrder({order: order, orderBy: orderBy})}
  
  const deleteActionClicked = (row: ICreateMeshDataset) => {  
    setConfirmDeletion({message: intl.formatMessage({id: 'warnings.deleteItem'}), itemsToDelete: [row]})
  }

  const handleDataDragged = useCallback((data: Array<ICreateMeshDataset>) => {
    const updatedPriority = data.map((d: ICreateMeshDataset, index: number) => {return {...d, priority: index + 1}})
    dispatch(setBathymetryDatasets(updatedPriority, true))
  }, [dispatch])

  const moveItem = useCallback((row: ICreateMeshDataset) => {  
    if (data && data.length > 1){
      const currentPriority = row.priority;
      const targetPriority = currentPriority === 1 ? 2 : currentPriority - 1
      const targetIndex = data.findIndex((r: ICreateMeshDataset) => r.priority === targetPriority)            
      const updatedRows = data.filter((r: ICreateMeshDataset) =>  r.priority !== currentPriority)
      updatedRows.splice(targetIndex, 0, row) 
      handleDataDragged(updatedRows)
    }    
  }, [data, handleDataDragged])

  const renderDeleteItem = useCallback((_value: any, row: ICreateMeshDataset) => {   
    return row && row.name !== GEBCO_BATHYMETRY ? (
      <div className={tableRowButtonStyle(!canUpdateContent)}>
        <Delete className={iconStyle(!canUpdateContent)} />        
      </div>
    ) : null
  }, [canUpdateContent])

  const renderMoveItem = useCallback(() => {   
    return data && data.length > 1 ? (
      <div className={tableRowButtonStyle(!canUpdateContent)}>
        <Arrow className={iconStyle(!canUpdateContent)} />        
      </div>
    ) : null
  }, [canUpdateContent, data])
 

  const columns = [...dataColumns, 
    {
      field: 'priority',
      label: 'Priority'      
    },
    {
      field: 'move',
      label: '',
      render: renderMoveItem,
      callback: moveItem,
      className: tableCellButtonStyle
    },
    {
      field: 'delete',
      label: '',
      render: renderDeleteItem,
      callback: deleteActionClicked,
      className: tableCellButtonStyle
    },    
  ]

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setConfirmDeletion(null);
    }
  }

  const deleteData = useCallback(() => {
    confirmDeletion && confirmDeletion.itemsToDelete && dispatch(deleteVtkItems(confirmDeletion.itemsToDelete, DATASETS.BATHYMETRY))
    setConfirmDeletion(null);
  }, [confirmDeletion, dispatch])

  return (
    <>
     <MikeDialog 
        open={confirmDeletion !== null} 
        onCancel={handleOnCancel} 
        onOk={deleteData}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.resetMesh'})}
        message={confirmDeletion ? confirmDeletion.message : ''}    
      />  
    
      <DataTable        
        loading={false}
        columns={columns}
        idField="id"   
        data={data}
        selectedRows={selectedItems ? selectedItems : new Array<ICreateMeshDataset>()}
        onCellClick={onItemClick ? onItemClick : () => {}}      
        onSelectionChange={onSelectionChange ? onSelectionChange : () => {}}
        onHandleRequestSort={onHandleRequestSort}
        orderBy={order.orderBy}
        order={order.order}   
        selectable={true}
        topOffset={0}   
        onDragEnd={handleDataDragged} 
      />
    </>
  );
};

export default BathymetryTable;
