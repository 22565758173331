import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import { Button } from '@material-ui/core';
import { fastWaveConfigSet, setDrawing, uploadBathymetry, uploadOutlineOrAOIOrShoreline } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { deleteOutputFolder, getProjectContent, setFilter, setProjectContentDialogOpen } from '../../actions/projectContent'
import { addMessage } from '../../actions/message'
import { IGetProject } from '../../model/IGetProject'
import {  AREAOFINTEREST, BATHYMETRY, DATASETS,  DRAWING, FLIPLAND, IMPORTS, MESH, OUTLINE, SHORELINE } from '../../shared/constants';
import UploadButton from '../UploadButton/UploadButton';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { IFastWaveConfig } from '../../model/IFastWaveConfig';
import { titleContainer, titleStyle, outlineTitleContainer, firstOutlineTitleContainer, titleButtonsContainer } from '../../shared/styles';
import { ReactComponent as Duplicate } from "@mike/mike-shared-frontend/media/icons/Duplicate"
import { ReactComponent as Draw } from "@mike/mike-shared-frontend/media/icons/Draw"
import MeshOutlineTable from '../MeshOutlineTable';
import { AOI, GEBCO, ICreateMeshDataset, ICreateMeshParameter } from '../../reducers/createMesh';
import MikeButton from '../mike-button';
import { createAutoMesh, interpolateMesh, setSelectedShoreline, updateParameters, updateSelectedAOIs, updateSelectedBathys } from '../../actions/createMesh';
import BathymetryTable from '../BathymetryTable';
import { useIntl } from 'react-intl';
import { ELEVATION, POINT_Z } from '../Viewer';
import ShorelineTable from '../ShorelineTable';
import  { IMeshInputEntity } from '../../model/CreateMeshScenarioOptions';
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import { IWarning, WARNINGTYPE } from '../InitialSelection';
import MikeDialog from '../DialogComponents/MikeDialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const subTitleStyle = css`
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
  color: ${theme.palette.primary.light};
`
const noMeshStyle = css`  
  padding-top: ${theme.spacing(2)}px;
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
`
const createMeshContainerStyle = css`  
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const checkingContainerStyle = css`  
  display: flex;  
  padding: ${theme.spacing(2)}px;
  align-items: center;
  `

const spinnerStyle = css`
 padding-right: ${theme.spacing(2)}px;
 padding-left: ${theme.spacing(2)}px;
`

const createMeshButtonStyle = css`  
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing(2)}px;
`
const ParameterLabelCss = css`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: ${theme.spacing(1)}px; 
`;

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({    
    switchLabel: {      
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main,
        fontSize: 14
      }
    },
    exportTitle: {
      padding: theme.spacing(3) + 'px '+ theme.spacing(2) + 'px',
      borderTop: '2px solid',
      borderColor: theme.palette.secondary.light
    },
    geometryButtonsContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2)     
    },
    geometryButton: {
      paddingRight: theme.spacing(1),
      whiteSpace: 'nowrap'
    },
    drawingActive: {
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.light
    },
    uploadIcon: {
      marginRight: theme.spacing(1)
    },
    pointsContainer: {
      backgroundColor: theme.palette.mediumGrey && theme.palette.mediumGrey.main
    },
    drawStyle: {
      marginLeft: '-10px',
      '& path': {
        fill: '#fff'
      }
    },   
    spinnerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    }
  })
})

const AutoMesh = () => {   
  const classes = styles()
  const dispatch = useDispatch();  
  const intl = useIntl();
  const showCopyXYZFromPlatform = false

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const {meshInputEntities, loadingShoreline, shorelines, checkingMeshIsExported, interpolatingMesh, 
    canInterpolateMesh, creatingMesh, loadingOutline, loadingAreasOfInterest, loadingBathymetry,
     meshOutline, meshAreasOfInterest, bathymetryDatasets, createMeshPayload } = useSelector(
    (state: IState) => state.createMesh
  ); 

  const fastWaveConfig: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );   

  const drawing = useSelector(
    (state: IState) => state.mapContent.drawing
  );   

  const invertMesh = useMemo(() => {
    const params: Array<ICreateMeshParameter>  = createMeshPayload && createMeshPayload.parameterDescriptions ? createMeshPayload.parameterDescriptions.filter((p: ICreateMeshParameter) => p.entityType.toLowerCase() === MESH.toLowerCase()) : []   
    const invertMeshParam = params.find((p: ICreateMeshParameter) => p.valueType.toLowerCase() === "boolean" && p.name.toLowerCase() === FLIPLAND)
    return invertMeshParam !== undefined ? invertMeshParam : null
  }, [createMeshPayload])

  const hasOwnShoreline = useMemo(() => {   
    return shorelines && shorelines.length > 0
  }, [shorelines])

  const supportedEntities = useMemo(() => {
    return meshInputEntities.map((entity: IMeshInputEntity) => entity.type.toLowerCase())
  }, [meshInputEntities])

  const outlineDefined = useMemo(() => {return meshOutline !== null}, [meshOutline])
  const areaOfInterestDefined = useMemo(() => {return meshAreasOfInterest && meshAreasOfInterest.length > 0}, [meshAreasOfInterest])
  const shorelineDefined = useMemo(() => {return shorelines && shorelines.length > 0}, [shorelines])

  const disableCreateMeshButton = useMemo(() => {
    const busy = loadingOutline || loadingAreasOfInterest || loadingShoreline || creatingMesh
    if (busy){
      return true
    }
    if (supportedEntities.length === 0){
      return true
    }
    if (supportedEntities.includes(OUTLINE) && !outlineDefined){
      return true
    }
    if (supportedEntities.includes(SHORELINE) && !shorelineDefined){
      return true
    }
    if (supportedEntities.includes(AOI) && !areaOfInterestDefined){
      return true
    }

    return false
  }, [supportedEntities, creatingMesh, outlineDefined, areaOfInterestDefined, shorelineDefined, loadingOutline, loadingAreasOfInterest, loadingShoreline])

  const canUpdateContent = useMemo(() => {
    return project && project.capabilities && project.capabilities.canUpdateContent
  }, [project]) 

  const dataFromDataLinkAlreadyExtracted = useMemo(() => {
    return fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id
  }, [fastWaveConfig]) 

  const [ fileTemp, setFileTemp ] = useState<any>();
  const [warning, setWarning] = React.useState<IWarning | null>(null);

  const handleCreateMesh = useCallback(() => {   
    dispatch(createAutoMesh()) 
  }, [dispatch])

  const handleInterpolateMesh = useCallback(() => {   
    dispatch(interpolateMesh(bathymetryDatasets.map((b: ICreateMeshDataset) => {return {variableId: b.id, propertyName: b.name.startsWith(GEBCO) ?  ELEVATION : POINT_Z}}))) 
  }, [dispatch, bathymetryDatasets])

  const openPlatformExplorer = useCallback((filter: string) => {   
    if (project){
      const canListContent = project.capabilities && project.capabilities.canListContent
      if (canListContent){ 
        dispatch(setFilter(filter))
        dispatch(getProjectContent(project, filter))
        dispatch(setProjectContentDialogOpen(true, true))
      }
      else{
        addMessage(intl.formatMessage({id: 'project.noReadAccess'}) +  " " + project.id + ". " + intl.formatMessage({id: 'project.contactOwner'}));
      } 
    }    
  }, [dispatch, intl, project])


  const handleImportGeometryFromPlatform = useCallback((filter: string) => {     
    if (dataFromDataLinkAlreadyExtracted){
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.COPYMESHINPUT, filter})       
    }
    else {
      openPlatformExplorer(filter)
    } 
  }, [intl, openPlatformExplorer, dataFromDataLinkAlreadyExtracted])

  const handleEnableDrawOutline= useCallback((filter: string) => { 
    if (dataFromDataLinkAlreadyExtracted){  
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.DRAWMESHINPUT, filter})       
    }
    else{
      dispatch(setDrawing(filter)) 
    }
  }, [dataFromDataLinkAlreadyExtracted, dispatch, intl])

  const onDropShapefile = useCallback(
    (files, importType: string) => {
      if (files.length > 0){       
        const file = files[0]   
        if (dataFromDataLinkAlreadyExtracted){
          setFileTemp(file)
          setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.UPLOADMESHINPUT, filter: importType})       
        }
        else{           
          dispatch(uploadOutlineOrAOIOrShoreline(file, file.name, importType));             
        }
      }
    }, [dataFromDataLinkAlreadyExtracted, dispatch, intl])
    
  const onDropBathymetry = useCallback(
  (files) => {
    if (files.length > 0){       
      const file = files[0]   
      dispatch(uploadBathymetry(file, file.name)) 
    }
  }, [dispatch])

  const handleSelectedAOIsChanged = (selectedItems: Array<ICreateMeshDataset>) => {
    dispatch(updateSelectedAOIs(selectedItems.map((item: ICreateMeshDataset) => item.id)))   
  }

  const handleSelectedBathysChanged = (selectedItems: Array<ICreateMeshDataset>) => {
    dispatch(updateSelectedBathys(selectedItems.map((item: ICreateMeshDataset) => item.id)))   
  }

  const handleSelectedShorelineChanged = (selectedItems: Array<ICreateMeshDataset>) => {
    const lastSelectedItem = selectedItems[selectedItems.length - 1]
    lastSelectedItem && lastSelectedItem.id && dispatch(setSelectedShoreline(lastSelectedItem.id))   
  }

  const selectedShorelines = useMemo(() => {
    return shorelines.filter((item: ICreateMeshDataset) => item.selected)
  }, [shorelines])

  const selectedAOIs = useMemo(() => {
    return meshAreasOfInterest.filter((item: ICreateMeshDataset) => item.selected)
  }, [meshAreasOfInterest])

  const selectedBathys = useMemo(() => {
    return bathymetryDatasets.filter((item: ICreateMeshDataset) => item.selected)
  }, [bathymetryDatasets])

  const interpolationIsRunning = useMemo(() => {
    return interpolatingMesh || checkingMeshIsExported
  }, [interpolatingMesh, checkingMeshIsExported])

  const disableCreateDomainButtons = useMemo(() => {
    return !canUpdateContent || loadingOutline || loadingBathymetry
  }, [canUpdateContent, loadingOutline, loadingBathymetry])

  const disableCreateAOIButtons = useMemo(() => {
    return !canUpdateContent || loadingAreasOfInterest
  }, [canUpdateContent, loadingAreasOfInterest])

  const disableCreateShorelineButtons = useMemo(() => {
    return !canUpdateContent || loadingShoreline
  }, [canUpdateContent, loadingShoreline])
 
  const reset = useCallback(() => { 
    const copyConfig = {...fastWaveConfig}
    delete copyConfig.data_link_output_file
    dispatch(fastWaveConfigSet(copyConfig));
    dispatch(deleteOutputFolder());    
  }, [dispatch, fastWaveConfig])

  const handleWarningConfirmed = useCallback(() => {
    setWarning(null)
    const warningType = warning && warning.type ? warning.type : ''
    switch (warningType){
      case WARNINGTYPE.COPYMESHINPUT:
        reset();
        dispatch(uploadOutlineOrAOIOrShoreline(fileTemp, fileTemp.name, warning.filter));  
        setFileTemp(null);
        break;
      case WARNINGTYPE.UPLOADMESHINPUT:  
        reset();    
        openPlatformExplorer(warning.filter);
        break;
      case WARNINGTYPE.DRAWMESHINPUT:
        reset(); 
        dispatch(setDrawing(warning.filter))  
        break;
      default:
        break;
    }
    
  }, [warning, reset, dispatch, fileTemp, openPlatformExplorer])

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setWarning(null);
      setFileTemp(null);
    }
  }

  const handleChangeInvertMesh = useCallback((event) => {
    dispatch(updateParameters({[event.target.name]: event.target.checked}, MESH))
  }, [dispatch])

  const getNumber = (entities: Array<string>) => {
    return entities.includes(SHORELINE) ? "3. " : "2. "
  }

  return (
    <>
      <MikeDialog 
        open={warning && warning.warning ? true : false} 
        onCancel={handleOnCancel} 
        onOk={handleWarningConfirmed}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.confirmDialogContentTitle'})}
        message={warning && warning.warning ? warning.warning : ''}    
        okButtonLabel={intl.formatMessage({id: 'warnings.change'})}
      />
   
      <div >     
        <div className={titleContainer}>
          <div>
            <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'autoMesh.mesh'})}</Typography>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.outlineAndAOI'})}</Typography>
          </div>
        </div>

        {supportedEntities.includes(OUTLINE) &&
          <div className={firstOutlineTitleContainer}>
            <div className={titleButtonsContainer}>
              <Typography variant="body1" className={titleStyle}><b>{"1. " + intl.formatMessage({id: 'autoMesh.outline'})}</b></Typography>          
              <div className={classes.geometryButtonsContainer}> 
                <Button disabled={disableCreateDomainButtons} variant="text" onClick={() => {handleImportGeometryFromPlatform(OUTLINE)}} className={classes.geometryButton}>            
                  <Duplicate className={iconSecondaryStyle(disableCreateDomainButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: outlineDefined ? 'platform.replace' : 'platform.copy'})}            
                </Button>   
                <UploadButton disabled={disableCreateDomainButtons} fileType={'.zip'} label={intl.formatMessage({id: outlineDefined ? 'desktop.replace' : 'desktop.upload'})} onDrop={(files) => onDropShapefile(files, IMPORTS.OUTLINE)} info={[intl.formatMessage({id: 'autoMesh.uploadShapefile_hint'})]}/> 
              
                <Button disabled={disableCreateDomainButtons} variant="text" onClick={() => {handleEnableDrawOutline(DRAWING.MESHOUTLINE)}} className={drawing === DRAWING.MESHOUTLINE ? classes.drawingActive : classes.geometryButton}>
                  <Draw className={iconSecondaryStyle(disableCreateDomainButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: outlineDefined ? 'draw.replace' : 'draw.onMap'})}               
                </Button>   
              </div> 
            </div>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.outlineTheDomain'})}</Typography> 
          </div>
        }
    
        {supportedEntities.includes(OUTLINE) ? loadingOutline ? 
          <div className={checkingContainerStyle}>
            <CircularProgress/>
            <Typography variant="body2" className={spinnerStyle}>{"Waiting for domain creation..."}</Typography>
          </div> :
          <div>
            <MeshOutlineTable 
              data={meshOutline ? [meshOutline] : []}           
              canUpdateContent={canUpdateContent}
              datasetType={DATASETS.OUTLINE}
            />
            {outlineDefined ? null : <Typography className={noMeshStyle} variant="body2">{intl.formatMessage({id: 'autoMesh.noOutlineDomain'})}</Typography>}
          </div>
        : null}

        {supportedEntities.includes(SHORELINE) &&
          <div className={outlineTitleContainer}>
            <div className={titleButtonsContainer}>
              <Typography variant="body1" className={titleStyle}><b>{"2. " + intl.formatMessage({id: 'autoMesh.shoreline'})}</b></Typography>              
              <div className={classes.geometryButtonsContainer}> 
                <Button disabled={disableCreateShorelineButtons} variant="text" onClick={() => {handleImportGeometryFromPlatform(DATASETS.OWN_SHORELINE)}} className={classes.geometryButton}>
                  <Duplicate className={iconSecondaryStyle(disableCreateShorelineButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: hasOwnShoreline ? 'platform.replace' : 'platform.copy'})}               
                </Button>   
                <UploadButton disabled={disableCreateShorelineButtons} fileType={'.zip'} label={intl.formatMessage({id: hasOwnShoreline ? 'desktop.replace' : 'desktop.upload'})} onDrop={(files) => onDropShapefile(files, IMPORTS.OWN_SHORELINE)} info={[intl.formatMessage({id: 'autoMesh.uploadShapefile_hint'})]}/> 
                <Button disabled={disableCreateShorelineButtons} variant="text" onClick={() => {handleEnableDrawOutline(DRAWING.OWN_SHORELINE)}} className={drawing === DRAWING.OWN_SHORELINE ? classes.drawingActive : classes.geometryButton}>
                  <Draw className={iconSecondaryStyle(disableCreateShorelineButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: hasOwnShoreline ? 'draw.replace' : 'draw.onMap'})}               
                </Button>   
              </div>
            </div>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.outlineShoreline'})}</Typography>              
          </div>
        }
        {supportedEntities.includes(SHORELINE) ? loadingShoreline ? 
          <div className={checkingContainerStyle}>
          <CircularProgress/>
          <Typography variant="body2" className={spinnerStyle}>{"Waiting for shoreline creation..."}</Typography>
        </div> :
          <div>
            <ShorelineTable 
              data={shorelines}           
              canUpdateContent={canUpdateContent}
              datasetType={DATASETS.OWN_SHORELINE}
              selectedItems={selectedShorelines}
              onSelectionChange={handleSelectedShorelineChanged}
            />
            {shorelineDefined ? null : <Typography className={noMeshStyle} variant="body2">{intl.formatMessage({id: 'autoMesh.noShoreline'})}</Typography>}
          </div>
        : null} 
  
        {supportedEntities.includes(AOI.toLowerCase()) &&
          <div className={outlineTitleContainer}>
            <div className={titleButtonsContainer}>
              <Typography variant="body1" className={titleStyle}><b>{getNumber(supportedEntities) + intl.formatMessage({id: 'autoMesh.aoi'})}</b></Typography>          
              <div className={classes.geometryButtonsContainer}> 
                <Button disabled={disableCreateAOIButtons} variant="text" onClick={() => {handleImportGeometryFromPlatform(AREAOFINTEREST)}} className={classes.geometryButton}>
                  <Duplicate className={iconSecondaryStyle(disableCreateAOIButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: areaOfInterestDefined ? 'platform.replace' : 'platform.copy'})}               
                </Button>   
                <UploadButton disabled={disableCreateAOIButtons} fileType={'.zip'} label={intl.formatMessage({id: areaOfInterestDefined ? 'desktop.replace' : 'desktop.upload'})} onDrop={(files) => onDropShapefile(files, IMPORTS.AREAOFINTEREST)} info={[intl.formatMessage({id: 'autoMesh.uploadShapefile_hint'})]}/> 
              
                <Button disabled={disableCreateAOIButtons} variant="text" onClick={() => {handleEnableDrawOutline(DRAWING.AREAOFINTEREST)}} className={drawing === DRAWING.AREAOFINTEREST ? classes.drawingActive : classes.geometryButton}>
                  <Draw className={iconSecondaryStyle(disableCreateAOIButtons)} width={24} height={24} viewBox={"0 0 40 40"}/>
                  {intl.formatMessage({id: areaOfInterestDefined ? 'draw.replace' : 'draw.onMap'})}               
                </Button>   
              </div>
            </div> 
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.outlineAOI'})}</Typography> 
          </div>
        }
        {supportedEntities.includes(AOI.toLowerCase()) ? loadingAreasOfInterest ? 
          <div className={checkingContainerStyle}>
          <CircularProgress/>
          <Typography variant="body2" className={spinnerStyle}>{"Waiting for Area of Interest creation..."}</Typography>
        </div> :
          <div>
            <MeshOutlineTable 
              data={meshAreasOfInterest}           
              canUpdateContent={canUpdateContent}
              datasetType={DATASETS.AREAOFINTEREST}
              selectedItems={selectedAOIs}
              onSelectionChange={handleSelectedAOIsChanged}
            />
            {areaOfInterestDefined ? null : <Typography className={noMeshStyle} variant="body2">{intl.formatMessage({id: 'autoMesh.noAOI'})}</Typography>}
          </div>
        : null} 
        <div className={createMeshContainerStyle}>
          {creatingMesh ? <Typography variant="body2" className={spinnerStyle}>{"Creating mesh..."}</Typography> : <div/>}
          {invertMesh && 
          <FormControlLabel
            control={
              <Switch
                checked={invertMesh.value === true ? true : false}
                onChange={handleChangeInvertMesh}
                name={invertMesh.name}
                inputProps={{ 'aria-label': invertMesh.name }}                
                />
              }
              label={invertMesh.displayName} 
              title={invertMesh.displayName}
              checked={invertMesh.value === true ? true : false}
              className={classes.switchLabel}
              classes={{ label: ParameterLabelCss }}
            />
          }
         
          <div className={createMeshButtonStyle}>
            <MikeButton disabled={disableCreateMeshButton} buttontype="secondary" active={creatingMesh} onClick={handleCreateMesh}>Create Mesh</MikeButton>
          </div>
        </div>
      
        <div className={titleContainer}>
          <div>
            <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'autoMesh.bathymetry'})}</Typography>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.loadGebcoOrOwn'})}</Typography>
          </div>         
          <div className={classes.geometryButtonsContainer}>
            {showCopyXYZFromPlatform && 
              <Button disabled={!canUpdateContent} variant="text" onClick={() => {handleImportGeometryFromPlatform(BATHYMETRY)}} className={classes.geometryButton}>
                <Duplicate className={iconSecondaryStyle(!canUpdateContent)} width={24} height={24} viewBox={"0 0 40 40"}/>
                {intl.formatMessage({id: 'platform.copy'})}                 
              </Button> 
            }  
            <UploadButton disabled={!canUpdateContent} fileType={['.asc', '.vti', '.vtu', '.xyz']} label={intl.formatMessage({id: 'desktop.upload'})} onDrop={onDropBathymetry}/> 
          </div>
        </div>
        {loadingBathymetry ? 
          <div className={checkingContainerStyle}>
          <CircularProgress/>
          <Typography variant="body2" className={spinnerStyle}>{"Waiting for bathymetry extraction..."}</Typography>
        </div> :
          <div>
            <BathymetryTable 
              data={bathymetryDatasets}           
              canUpdateContent={canUpdateContent}
              datasetType={DATASETS.BATHYMETRY}
              selectedItems={selectedBathys}
              onSelectionChange={handleSelectedBathysChanged}
            />        
          </div>
        } 
        <div className={createMeshContainerStyle}>
          {interpolationIsRunning ? <Typography variant="body2" className={spinnerStyle}>{"Interpolating and exporting mesh..."}</Typography> : <div/>}
          <div className={createMeshButtonStyle}>
            <MikeButton disabled={!canInterpolateMesh} active={interpolationIsRunning} onClick={handleInterpolateMesh} buttontype="secondary">Interpolate</MikeButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default AutoMesh