/* eslint-disable no-console */
import {
  BATHYMETRY,
  DATASETS,
  GET_EXPORT_INFO_DELAY, IMPORTS, MESH

} from '../shared/constants'
import { call, put, takeEvery, select, delay, all } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { SRID_PARAM, mapUserImportDataToConvertApiBody } from '../helpers/import'

import {
  transferUploadConvert,
  TRANSFER_STATUSES,
  getTransfer
} from '../apis/transfer'

import {
  importStart,
  updateProgress,
  importFail,
  importComplete,
  removeImport,
  copyComplete, 
  copyFail,
  copyDatasetToOtherProject
} from '../actions/imports'
import { IState } from '../reducers'
import { areaOfInterestUploadedOrSelected, bathymetryDatasetsUploadedOrSelected, meshUploaded, outlineUploaded, setupUploaded, shorelineUploadedOrSelected } from '../actions/mapContent'
import { processContainer } from '../apis/backendApi'
import { setJobId } from '../actions/job'
import { addError } from '../actions/errors'
import { dateTimeFormat } from '../helpers/fixTime'
import { copyDataset, getProjectDatasets } from '../apis/metadataApi'
import { IGetDataset } from '../model/IGetDataset'
import { getCopyDatasetPayload } from '../helpers/copyDataset'
import { ITrackProgressItem } from '../model/ITrackProgressItem'
import { removeProgressItem, updateOrAddProgressItem } from '../actions/progressItems'
import { setOwnDataUploaded } from '../actions/ownData'
import { loadingAOI, loadingOutline, loadingShoreline } from '../actions/createMesh'
import { getNiceJobName } from '../helpers/jobs'
import { IGetProject } from '../model/IGetProject'
import { getProject } from '../reducers/state'

export const getUser = (state: IState) => state.auth.user

export default function* watchImports() {
  yield takeEvery(ActionType.IMPORT_FILE, importFile)
  yield takeEvery(ActionType.COPY_DATASETS, handleCopyDatasets)
  yield takeEvery(ActionType.COPY_DATASET, handleCopyDatasetToOtherProject)
}

function* handleCopyDatasets(action){
  const {projectId, datasets, datasetType} = action.data
   yield all(
    datasets.map((dataset: IGetDataset) =>
      put(copyDatasetToOtherProject(projectId, dataset, datasetType))
    )
  )
}

function* handleCopyDatasetToOtherProject(action) {

  const { projectId, dataset, datasetType } = action.data
  const srid = dataset.spatialInformation && dataset.spatialInformation.srid ? dataset.spatialInformation.srid : null;
  const data = getCopyDatasetPayload(dataset.datasetFormat)
  const id = dataset.id
  let result
  try {
    const body = {
      outputProjectId: projectId,
      outputDatasetData: {
        name: dataset.name,
        description: dataset.description
      },
      readerName: data.readerName,
      writerName: data.writerName,
      readerParameters: srid ?  [{ name: SRID_PARAM, value: srid }] : [],
      writerParameters: []
    }   
    
    result = yield call(
      copyDataset,
      id,
      body
    )
    
    const title = 'Copying (' + dataset.name + ')'   
    const progressItem: ITrackProgressItem = {
      id,
      title,
      progressValue: 25
    }
    yield put(updateOrAddProgressItem(progressItem))
  

    let info
    while (true) {
      const project : IGetProject | null = yield select(getProject);
      if (project && project.id && project.id !== projectId){
        yield put(removeProgressItem({id: dataset.id, title: "", progressValue: 0}))
        break;
      }
      info = yield call(getTransfer, result.id)     

      if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
        yield put(updateProgress(id, 50, title))
      } else if (info.status === TRANSFER_STATUSES.ERROR) {
        throw new Error(info.errorMessage)
      } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
        break
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }

    const resultingId = info.importResults  && info.importResults.length > 0 && info.importResults[0].datasetId ? info.importResults[0].datasetId : ''

    if (resultingId){
      switch (datasetType){
        case DATASETS.OUTLINE:
          yield put(outlineUploaded(resultingId));
          break;
        case DATASETS.AREAOFINTEREST:
          yield put(areaOfInterestUploadedOrSelected([resultingId]));
          break;
        case MESH: 
          yield put(meshUploaded(resultingId))
          break;
        case BATHYMETRY:
          yield put(bathymetryDatasetsUploadedOrSelected([resultingId]))
          break;
        case DATASETS.OWN_SHORELINE:
          yield put(shorelineUploadedOrSelected(resultingId))
          break;
      }
    }
    
    
    yield put(copyComplete(id))    
   
  } catch (error) {   
    // yield put(addError(error)); 
    yield put(copyFail(dataset.id, error))
  }
  finally{
    yield put(removeProgressItem({id: dataset.id, title: "", progressValue: 0}))   
  }
  
}

function* importFile(action) { 

  const { id, fileName, fileUrl, projectId, data, importType, startContainer } = action.data
  
  let result
  try {      
      result = yield call(
        transferUploadConvert,
        mapUserImportDataToConvertApiBody(projectId, fileName, fileUrl, data)
      ) 
   
    const title = 'Importing (' + data.name + ')'
    const user = yield select(getUser)

    const createdBy = user.email
    if (importType === IMPORTS.OUTLINE){
      yield put(loadingOutline(true))
    }
    else if (importType === IMPORTS.AREAOFINTEREST){
      yield put(loadingAOI(true))
    }
    else if (importType === IMPORTS.OWN_SHORELINE){
      yield put(loadingShoreline(true))
    }
    yield put(
      importStart(
        result.id,
        createdBy,
        projectId,
        TRANSFER_STATUSES.IN_PROGRESS,
        data,
        id
      )
    )
  
    let info
    while (true) {
      const project : IGetProject | null = yield select(getProject);
      if (project && project.id && project.id !== projectId){
        yield put(removeProgressItem({id, title: "", progressValue: 0}))
        break;
      }
      info = yield call(getTransfer, result.id)   

      if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
        yield put(updateProgress(id, 50, title))
      } else if (info.status === TRANSFER_STATUSES.ERROR) {
        if (importType === IMPORTS.OUTLINE){
          yield put(loadingOutline(false))
        }
        else if (importType === IMPORTS.AREAOFINTEREST){
          yield put(loadingAOI(false))
        }
        else if (importType === IMPORTS.OWN_SHORELINE){
          yield put(loadingShoreline(false))
        }
        throw new Error(info.errorMessage)
      } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
        break
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }
   
    if (importType === IMPORTS.MESH){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){
        yield put(meshUploaded(info.importResults[0].datasetId))
      }      
    }
    else if (importType === IMPORTS.OUTLINE){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){
        yield put(outlineUploaded(info.importResults[0].datasetId))
      }      
    }
    else if (importType === IMPORTS.AREAOFINTEREST){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){      
        yield put(areaOfInterestUploadedOrSelected([info.importResults[0].datasetId]))
      }      
    }
    else if (importType === IMPORTS.OWN_SHORELINE){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){      
        yield put(shorelineUploadedOrSelected(info.importResults[0].datasetId))
      }      
    }
    else if (importType === IMPORTS.BATHYMETRY){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){      
        yield put(bathymetryDatasetsUploadedOrSelected([info.importResults[0].datasetId]))
      }      
    }
    else if (importType === IMPORTS.OWNDATA){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){      
        yield put(setOwnDataUploaded(info.importResults[0].datasetId))
      }      
    }
    else if (importType === IMPORTS.SETUP){
      if (info.importResults && info.importResults.length > 0 && info.importResults[0].datasetId){
        const id = info.importResults[0].datasetId
        const datasets = yield call(getProjectDatasets, info.importResults[0].projectId)
        const dataset = datasets.find((ds: IGetDataset) => ds.id === id)
        if (dataset !== undefined){
          yield put(setupUploaded(dataset))
        }        
      }      
    }
    else if (startContainer !== ""){
      try{       
        const response = yield call(processContainer, projectId, startContainer.toString())
        if (response) {
          const name = getNiceJobName(startContainer.toString())  
          yield put(setJobId(startContainer.toString(),response, dateTimeFormat(Date.now()), name, projectId))
        }        
      }
      catch (error){
        yield put(addError(error)); 
      } 
    }
    yield put(importComplete(id, importType))
    // yield delay(EXPORT_REMOVE_ON_DONE_DELAY)
    yield put(removeImport(id))
  } catch (error) {  
    if (error && error.message === "Undefined coordinate system."){
      yield put(importFail(id, "Sorry, we could not recognize the projection of your file. Please try to upload again but this time select the projection yourself."));
    }
    else{
      yield put(importFail(id, error))
    }
  }  
}
