import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import {  pointUpdate,  uploadPoints, uploadPointsUrlSet } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { IGetProject } from '../../model/IGetProject'
import EditPointsForm from '../EditPointsForm';
import { IValue } from '../EditPointsForm/EditPointsForm';
import UploadButton from '../UploadButton/UploadButton';
import { titleContainer, titleStyle, subTitleStyle } from '../../shared/styles';
import { useIntl } from 'react-intl';

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({    
    exportTitle: {
      padding: theme.spacing(3) + 'px '+ theme.spacing(2)+ 'px',
      borderTop: '2px solid',
      borderColor: theme.palette.secondary.light
    },
    geometryButtonsContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'     
    },
    geometryButton: {
      paddingRight: theme.spacing(1)
    },
    uploadIcon: {
      marginRight: theme.spacing(1)
    },
    pointsContainer: {
      backgroundColor: theme.palette.mediumGrey && theme.palette.mediumGrey.main
    },
    drawStyle: {
      marginLeft: '-10px',
      '& path': {
        fill: '#fff'
      }
    },   
    spinnerRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
})

const Points = () => {   
  const classes = styles()
  const dispatch = useDispatch();
  const intl = useIntl();

  const loadingPoints: boolean = useSelector((state: IState) => state.mapContent.loadingConfig)  

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const points = useSelector(
    (state: IState) => state.mapContent.points
  );  

  const [features, setFeatures ] = useState([])

  useEffect(() => {
      setFeatures(points) 
   }, [points]); 

  const onDropPoints = useCallback(
    (files) => {
      if (files.length > 0){
        dispatch(uploadPointsUrlSet(""))
        const file = files[0]   
        dispatch(uploadPoints(file, file.name, project.id)) 
      }
    },
    [dispatch, project])

  const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent

  const onSubmit = (values: IValue) => {
    const fixFeatures = values.features.map((f: any) => {return { ...f, geometry: {type: "Point",
    coordinates: [parseFloat(f.geometry.coordinates[0]),parseFloat(f.geometry.coordinates[1] )]} }})
    dispatch(pointUpdate(fixFeatures))
  }

  return (  
    <div>
      <div className={titleContainer}>
        <div>
          <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'points.outputPoints'})}</Typography>
          <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'points.clickOnMapToPlaceAPoint'})}</Typography>
        </div>         
        <div className={classes.geometryButtonsContainer}>
          <UploadButton disabled={!canCreateContent} fileType={['.csv', '.txt', '.xy', '.xyz']} label={intl.formatMessage({id: 'points.uploadXYZ'})} onDrop={onDropPoints}/> 
        </div>
      </div>
      
      
      <div>
        {loadingPoints ? <CircularProgress/> :         
          <EditPointsForm
            initialValues={{newName: "", newLong: "", newLat: "", features: features}}
            onSubmit={onSubmit}        
            />
          } 
        </div> 
    </div>
  )
}

export default Points