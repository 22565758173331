import React from 'react';
import { orderBy as _orderBy } from 'lodash';

export interface IColumn {
  disablePadding?: boolean;
  field: string;
  label: string;
  numeric?: boolean;
  render?: (value: any, item?: any, data?: Array<any>) => React.ReactNode | string | number;
  comparator?: (item: any) => string | number;
  className?: (item?: any) => string;
  callback?: (item: any) => void;
}

export const getPadding = (col: IColumn) => (col.disablePadding ? 'none' : 'normal');

export const getSortedPageRows = (
  data: Array<any>,
  orderBy: string | ((item: any) => string | number),
  orderDir: 'asc' | 'desc',
  page?: number,
  rowsPerPage?: number
) => {
  const sortedData = _orderBy(data, orderBy, orderDir);
  return rowsPerPage && page !== undefined
    ? getPageRows(page, rowsPerPage, sortedData)
    : sortedData;
};

const getPageRows = (page: number, rowsPerPage: number, data: Array<any>) =>
  data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

export const getRender = (col: IColumn) => col.render || ((value) => value);
