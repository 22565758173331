import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { ICapability, IGetProject, IGetProjectPath } from '../model/IGetProject';
import { IGetDataset } from '../model/IGetDataset';

export interface IProjectContentState {
  loadingProjectAndParent: boolean;
  loadingProjectContent: boolean;
  projectContentDialogIsOpen: boolean;
  projectContentDialogIsSelectable: boolean;
  project: IGetProject | null;
  
  projectContent: Array<IGetProject | IGetDataset>;
  currentNavigationProject: IGetProject;
  currentNavigationParentProject: IGetProject;
  currentNavigationProjectPath: IGetProjectPath[];
  currentNavigationProjectCapability: ICapability | null;
  page: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string | ((item: any) => string | number)
  filter: string;  
  hasModelResults: boolean;
}

const initState = {
  loadingProjectAndParent: false,
  loadingProjectContent: false,
  project: null,   
  projectContent: new Array<IGetProject | IGetDataset>(),
  currentNavigationProject: null,
  currentNavigationParentProject: null,
  currentNavigationProjectPath: new Array<IGetProjectPath>(),
  currentNavigationProjectCapability: null,
  projectContentDialogIsOpen: false,
  projectContentDialogIsSelectable: false,
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: '',  
  filter: "",
  hasModelResults: false
}

const projectContent = (state = initState, action: IAction<any>) => {
  switch (action.type) {
    case ActionType.SET_HAS_MODEL_RESULTS:
      return {...state, hasModelResults: action.data}
    case ActionType.OPEN_PROJECTCONTENT_DIALOG:
      return { ...state, projectContentDialogIsOpen: action.data.open, projectContentDialogIsSelectable: action.data.selectable }; 
    case ActionType.LOADING_PROJECT:
      return { ...state, loadingProjectAndParent: action.data }; 
    case ActionType.FILTER_SET:
      return { ...state, filter: action.data }; 
    case ActionType.LOADING_PROJECTCONTENT:
      return { ...state, loadingProjectContent: action.data };    
    case ActionType.SET_PROJECTCONTENT:
      {     
        return {
          ...state,     
          projectContent: action.data.projectContent,
          currentNavigationParentProject: action.data.parent,
          currentNavigationProject: action.data.project,
          currentNavigationProjectPath: action.data.path,
          currentNavigationProjectCapability: action.data.capabilities,
          loadingProjectContent: false
        };   
      }      
    case ActionType.SET_PROJECT:{
      const { project, projectPath } = action.data
        return {
          ...state,
          project,
          projectPath,           
          loadingProjectAndParent: false
        };
      }
      
      case ActionType.SET_PROJECTCONTENT_PAGE:
        return {
          ...state,
          page: action.data
        };
      case ActionType.SET_PROJECTCONTENT_ROWSPERPAGE:
        return {
          ...state,
          rowsPerPage: action.data
        };
      case ActionType.SET_PROJECTCONTENT_ORDER:
        return {
          ...state,
          order: action.data
        };
      case ActionType.SET_PROJECTCONTENT_ORDERBY:
        return {
          ...state,
          orderBy: action.data
        };     
  
    default:
        return state;
  }
};

export default projectContent