import React, { useCallback, useMemo, useState} from 'react';
import { css } from 'emotion';
import { mikePalette } from '../../shared/mikeSharedTheme';
import theme from '../../shared/mikeSharedTheme';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EyeVisible } from "@mike/mike-shared-frontend/media/icons/EyeVisible";
import { ReactComponent as EyeHidden } from "@mike/mike-shared-frontend/media/icons/EyeHidden";
import { ReactComponent as Info } from "@mike/mike-shared-frontend/media/icons/Info"
import { useDispatch } from 'react-redux';
import { hideLayer, showLayer } from '../../actions/legend';
import { ILayerGroup, ILegendLayer } from '../../reducers/legend';
import { iconPrimaryStyle } from '../EditPointsForm/iconStyles';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import AnnotationUtils from './annotation-utils';
import { ELEVATION } from '../Viewer/Viewer';

const TitleContainerStyle = (visible: boolean) => {
  const backgroundColor = visible ? mikePalette.background.paper : mikePalette.lightGrey.main;  
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;  
    background-color: ${backgroundColor};
    border-top: 1px solid ${mikePalette.mediumGrey.main};
  `;
}

export const TitleStyle = (visible: boolean) => {  
  const color = visible ? mikePalette.primary.main : mikePalette.primary.light;
  return css`
  padding-left: ${theme.spacing(6)}px;
  &.MuiTypography-root {
    color: ${color};
  }  
`;
}

const containerStyle = css`
  background-color: ${theme.palette.background.default}; 
  padding-right: ${theme.spacing(1)}px;
`;

const buttonContainerStyle = css`
  display: flex;
`;

const legendListitemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const legendListitemColorStyle = css`
  width: ${theme.spacing(2)}px;
  height: ${theme.spacing(2)}px;
  padding: 0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

const legendListitemTextStyle = css`
  padding: 0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px;
  color: ${theme.palette.primary.main};
  font-size: ${theme.spacing(1.5)}px;
`;

const typographyH5Style = css`
  margin: 0;
  text-align: right;
`;

interface IProps {
  layerGroup: ILayerGroup;
  layer: ILegendLayer; 
}

export interface IAnnotatedValue {
  value;
  annotation: string;
  rgba: Array<number>;
}

export interface IDrawnDataLegend {
  elementId: string;
  legendItems: Array<IAnnotatedValue>;
  title: string;
}
const MAX_NUMBER_OF_ITEMS = 10;

export const Layer = (props: IProps) => {
  const dispatch = useDispatch();
  const {layer, layerGroup} = props;

  

  const colorLegend = useMemo(() => {
    if (layer && !layer.isTwoD && layer.visible){
      const { getState } = MikeVisualizerLib;
      const { renderedElements } = getState();
      const renderedElement = renderedElements.find(({ id }) => id === layer.id);
      if (renderedElement.gradientApplied){
        const gradientSettings = renderedElement.gradientSettings;
        const gradientAttributeName = renderedElement.gradientAttributeName;
        const gradientDataArray = renderedElement.dataArrays.find(({ id }) => id === gradientAttributeName);
        const annotatedValues = AnnotationUtils.getAnnotatedValues(layer.id, gradientDataArray, gradientSettings);
        const legendData: IDrawnDataLegend = {
          elementId: layer.id,
          legendItems: annotatedValues,
          title: gradientAttributeName,
        };
        return legendData;
      }
    }
    return null
  }, [layer])

  const [colorMapOpen, setColorMapOpen] = useState(false);

  

  const renderlegend = (legendData: IDrawnDataLegend) => {
    if (!legendData || !legendData.legendItems || !legendData.legendItems.length) {
      return null;
    }

    const getNiceTitle = (title: string) => {
      if (title.toLowerCase().includes(ELEVATION)){
        return "Elevation"
      }
      else{
        return title
      }
    }

    const { legendItems } = legendData;

    // If there are too many items, we pick every nTh.
    const nTh = legendItems.length > MAX_NUMBER_OF_ITEMS ? Math.ceil(legendItems.length / MAX_NUMBER_OF_ITEMS) : 1;

    return (
      <div className={containerStyle} key={legendData.elementId}>
        <Typography className={typographyH5Style} variant={'body2'}>
          <b>{getNiceTitle(legendData.title)}</b>
        </Typography>
        {legendItems.map((item, index) => {
          if (index % nTh !== 0) {
            return null;
          }

          const rgbaStyle = item.rgba ? item.rgba.join(',') : null;

          return (
            <div key={index} className={legendListitemStyle}>
              <div className={legendListitemTextStyle}>{item.annotation}</div>
              <div className={legendListitemColorStyle} style={{ backgroundColor: 'rgba(' + rgbaStyle + ')' }} />
            </div>
          );
        })}
      </div>
    );
  };

  const handleToggleVisibilty = useCallback(() => {
    const changeToHide = layer.visible
    if (changeToHide){
     dispatch(hideLayer(layerGroup, layer))
    }
    else{ 
      dispatch(showLayer(layerGroup, layer))
    }
  }, [dispatch, layer, layerGroup])

  const handleToggleLegendVisibilty = () => {
    setColorMapOpen((previous) => !previous)
  }

  return (     
    <div>
      <div className={TitleContainerStyle(layer.visible)}> 
        <Typography className= {TitleStyle(layer.visible)}  variant="body2"><b>{layer.title}</b></Typography>
        <div className={buttonContainerStyle}>
          {colorLegend && <Info onClick={handleToggleLegendVisibilty}/>}
          {layer.visible ? <EyeVisible onClick={handleToggleVisibilty}/> : <EyeHidden className={iconPrimaryStyle(true)} onClick={handleToggleVisibilty}/>}
        </div>
      </div>
      {colorMapOpen && colorLegend && <div>
        {renderlegend(colorLegend)}
      </div>}
    </div>
  );
};

export default Layer