import React, { useCallback, useMemo } from 'react'
import { Formik, Form, } from 'formik'
import TextField from '../formik/TextField'
import * as Yup from "yup";
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import MikeButton from '../mike-button/MikeButton';

import { ICreateMeshDataset, ICreateMeshInfo, ICreateMeshParameter } from '../../reducers/createMesh';
import MikeDialog from '../DialogComponents/MikeDialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { updateParameters } from '../../actions/createMesh';
import { useIntl } from 'react-intl';
import ContextHelp from '../ContextHelp';

const titleStyle = css`
  padding-bottom:${theme.spacing(1)}px;
`
const buttonsContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing(2)}px; 
`
 const dialogContent = css`
    background-color: ${theme.palette.background.paper};    
    paddingBottom: ${theme.spacing(5)}px;
  `

  const flexStyle = css`
  display: flex
`

interface IProps {  
  datasetType: string; 
  createMeshDataset: ICreateMeshDataset; 
  onCancel: () => void;
  onOk: () => void;
}
const numericTypes = ["integer", "double"]
const MeshOutlinePropertiesForm = (props: IProps) => { 
  const { datasetType, createMeshDataset, onCancel, onOk } = props;
  const intl = useIntl();
  const dispatch = useDispatch();



  const createMeshPayload: ICreateMeshInfo = useSelector((state: IState) => state.createMesh.createMeshPayload); 

  const parameters: Array<ICreateMeshParameter> = useMemo(() => {
    const params = createMeshPayload && createMeshPayload.parameterDescriptions ? createMeshPayload.parameterDescriptions.filter((p: ICreateMeshParameter) => p.entityType.toLowerCase() === datasetType.toLowerCase()) : []   
    return params
  }, [createMeshPayload, datasetType])

  const {initialValues, validationSchema } = useMemo(() => {
    if (parameters && parameters.length > 0){
      const defaultValues =  parameters.map((p: ICreateMeshParameter) => {
        return [p.name, p.value ? p.value : p.defaultValue]
      })    
      const isRequired = "is required"
      const numericValueRequired = "is required as numeric value" 
      const entries =  parameters.map((p: ICreateMeshParameter) => { 
        return [p.name, numericTypes.includes(p.valueType) ?  Yup.number().typeError(numericValueRequired).required(isRequired).min(p.minValue, p.minValue + "-" + p.maxValue).required(p.minValue + "-" + p.maxValue).max(p.maxValue, p.minValue + "-" + p.maxValue).required(p.minValue + "-" + p.maxValue) : Yup.string().required(isRequired)]
      })
      const val = Yup.object().shape(Object.fromEntries(entries))
      return { initialValues: Object.fromEntries(defaultValues), validationSchema: val} //Yup.object().shape({params})} ;
    }
    return { initialValues: undefined, validationSchema: undefined }
  }, [parameters]) 

  const onSubmit = useCallback((values: any) => {     
    let vals = {...values}
    const keys = Object.keys(values)
    keys.forEach((key: string) => {
      const parameter = parameters.find((p: ICreateMeshParameter) => p.name === key)
      const isNumeric = numericTypes.includes(parameter.valueType)
      vals[key] = isNumeric ? parseInt(values[key]) : values[key]
    })
    dispatch(updateParameters(vals, datasetType, createMeshDataset.id)) 
    onOk();   
  }, [dispatch, onOk, parameters, createMeshDataset, datasetType])

  return (   
    <MikeDialog 
      open={createMeshDataset !== null}
      onCancel={onCancel} 
      hideActions
      dialogTitle={intl.formatMessage({id: 'outlineProperties.parameters'})}    
    >  
      <MuiDialogContent className={dialogContent}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize     
          onSubmit={onSubmit}
          render={formikProps => {
            const { handleChange, setFieldTouched, handleSubmit, isValid } = formikProps
          
            const handleOnChange = (name, e) => {
              e.persist()
              handleChange(e)
              setFieldTouched(name, true, false)              
            } 

            return (
              <Form>
                <Typography className={titleStyle} variant="body2">{intl.formatMessage({id: 'outlineProperties.adaptDefaultValues'})}</Typography>
                {parameters.map((p: ICreateMeshParameter, index: number) => {
                  return (
                    <div className={flexStyle} key={index}>
                       <TextField                        
                        onChange={handleOnChange.bind(null, 'name')}
                        name={p.name}       
                        label={p.displayName}                                        
                        addPlaceHolder={true}
                      />
                      {p.info &&  <ContextHelp helpTexts={p.info} primary/>}                     
                    </div>                   
                    )
                  })
                }
              
                <div className={buttonsContainerStyle}>
                  <MikeButton disabled={!isValid} id="resolutionButton" buttontype="text" onClick={() => handleSubmit()}>
                    {intl.formatMessage({id: 'outlineProperties.submitChange'})}               
                  </MikeButton>
                </div>
              </Form>        
            )}}
          />
        </MuiDialogContent>
      </MikeDialog> 
  )
}

export default MeshOutlinePropertiesForm