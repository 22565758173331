import React, { useCallback, useMemo, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { AOI, ICreateMeshDataset, ICreateMeshInfo, ICreateMeshParameter } from '../../reducers/createMesh';
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as Resolution } from "@mike/mike-shared-frontend/media/icons/Resolution"
import {  iconStyle, tableCellButtonStyle, tableRowButtonStyle } from '../TableComponents/renderers';
import MikeDialog from '../DialogComponents/MikeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVtkItems } from '../../actions/createMesh';
import MeshOutlinePropertiesForm from '../MeshOutlinePropertiesForm';
import { DATASETS } from '../../shared/constants';
import { dateTimeRender } from '../Project/projectContentColumns';
import { useIntl } from 'react-intl';
import { IState } from '../../reducers';
import LongText from '../renders/LongText';

interface IProps { 
  data: Array<ICreateMeshDataset >;
  canUpdateContent: boolean;
  selectedItems?: Array<ICreateMeshDataset>;  
  onItemClick?: (item:  ICreateMeshDataset ) => void;
  onSelectionChange?: (selectedItems: Array<ICreateMeshDataset>) => void;
  datasetType: string;
}

interface IDeletion {
 message: string;
 itemsToDelete: Array<ICreateMeshDataset>;
}
 
 const itemNameRender = (value: string) => {
  const name = value === AOI ? "Area of Interest" : value
   return <LongText text={name} />;
 }

const dataColumns =[
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
  },
  {
    field: 'itemType',
    label: 'Type'
  },
  {
    field: 'updated',
    label: 'Edited on',
    render: dateTimeRender   
  }
] 
const MeshOutlineTable = (props: IProps) => {
  const { 
    data,   
    canUpdateContent,
    selectedItems, 
    onItemClick,
    onSelectionChange,
    datasetType
  } = props;
  
  const dispatch = useDispatch();
  const intl = useIntl();

  const createMeshPayload: ICreateMeshInfo = useSelector((state: IState) => state.createMesh.createMeshPayload); 

  const hasProerties = useMemo(() => {
    if (createMeshPayload && datasetType){
      const parameters = createMeshPayload && createMeshPayload.parameterDescriptions ? createMeshPayload.parameterDescriptions.filter((p: ICreateMeshParameter) => p.entityType.toLowerCase() === datasetType.toLowerCase()) : []  
      return parameters.length > 0 
    }
    return false
  }, [createMeshPayload, datasetType])
  
  
  const [order, setOrder] = useState<{order: 'asc' | 'desc', orderBy: string | ((item: ICreateMeshDataset) => string | number)}>({order: 'asc', orderBy: 'name'})
  const [confirmDeletion, setConfirmDeletion] = useState<IDeletion | null>(null);
  const [vtkItemToUpdateProperties, setVtkItemToUpdateProperties] = useState<ICreateMeshDataset | null>(null);

  const onHandleRequestSort =  (
    orderBy: string | ((item: ICreateMeshDataset) => string | number),
    order: 'asc' | 'desc'
  ) => {setOrder({order: order, orderBy: orderBy})}

  const renderDeleteItem = useCallback(() => {
    return (
      <div className={tableRowButtonStyle(!canUpdateContent)}>
        <Delete className={iconStyle(!canUpdateContent)} />        
      </div>
    )
  }, [canUpdateContent])

  const renderPropertiesItem = useCallback(() => {
   
      return hasProerties ? (
        <div className={tableRowButtonStyle(!canUpdateContent)}>
          <Resolution className={iconStyle(!canUpdateContent)} />        
        </div>
      ) : null
    
   
  }, [canUpdateContent, hasProerties])

  const deleteActionClicked = (row: ICreateMeshDataset) => {  
    setConfirmDeletion({message: intl.formatMessage({id: 'warnings.deleteItem'}), itemsToDelete: [row]})
  }

  const propertiesActionClicked = (row: ICreateMeshDataset) => {  
    setVtkItemToUpdateProperties(row)
  }

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setConfirmDeletion(null);
    }
  }

  const deleteData = useCallback(() => {
    confirmDeletion && confirmDeletion.itemsToDelete && dispatch(deleteVtkItems(confirmDeletion.itemsToDelete, datasetType))
    setConfirmDeletion(null);
  }, [datasetType, confirmDeletion, dispatch])

  const columns = [...dataColumns, 
    {
      field: 'properties',
      label: '',
      callback: propertiesActionClicked,
      render: renderPropertiesItem,  
      className: tableCellButtonStyle    
    },
    {
      field: 'delete',
      label: '',
      callback: deleteActionClicked,
      render: renderDeleteItem,
      className: tableCellButtonStyle
    }    
  ]
  
  return (
    <>
     <MikeDialog 
        open={confirmDeletion !== null} 
        onCancel={handleOnCancel} 
        onOk={deleteData}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.resetMesh'})}
        message={confirmDeletion ? confirmDeletion.message : ''}    
      />
    
      <MeshOutlinePropertiesForm 
        createMeshDataset={vtkItemToUpdateProperties}         
        datasetType={datasetType}
        onCancel={(_event?, reason?) => {
          if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
            setVtkItemToUpdateProperties(null)
          }
        }}
        onOk={() => setVtkItemToUpdateProperties(null)}
      />     
    
      <DataTable
        loading={false}
        columns={columns}
        idField="id"   
        data={data}
        selectedRows={selectedItems ? selectedItems : new Array<ICreateMeshDataset>()}
        onCellClick={onItemClick ? onItemClick : () => {}}      
        onSelectionChange={onSelectionChange ? onSelectionChange : () => {}}
        onHandleRequestSort={onHandleRequestSort}
        orderBy={order.orderBy}
        order={order.order}   
        selectable={datasetType === DATASETS.AREAOFINTEREST}
        topOffset={0}    
      />
    </>
  );
};

export default MeshOutlineTable;
