
import { ILayerGroup, ILegendLayer } from "../reducers/legend"
import ActionType from "./ActionType"


export const addLayerGroup = (title: string) => ({
  type: ActionType.LEGENDGROUP_ADD_GROUP,
  data:  title
})

export const setLegendGroupLayersLoaded = (title: string) => ({
  type: ActionType.LEGENDGROUP_SET_LAYERS_LOADED,
  data:  title
})

export const removeLayerGroup = (title: string) => ({
  type: ActionType.LEGENDGROUP_REMOVE_GROUP,
  data:  title
})

export const addLayer = (groupTitle: string, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_ADD_LAYER,
  data:  {groupTitle, layer}
})

export const removeLayers = (groupTitle: string, layers: Array<ILegendLayer>) => ({
  type: ActionType.LEGENDGROUP_REMOVE_LAYERS,
  data:  {groupTitle, layers}
})

export const deleteLayer = (legendGroup: ILayerGroup, id: string) => ({
  type: ActionType.LEGENDGROUP_DELETE_LAYER,
  data: {legendGroup, id}
})

export const hideLayer = (layerGroup: ILayerGroup, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_HIDE_LAYER,
  data: {layerGroup, layer}
})

export const hideLayerByNames = (layerGroupName: string, layerNames: Array<string>) => ({
  type: ActionType.LEGENDGROUP_HIDE_LAYER_BY_NAME,
  data: {layerGroupName, layerNames}
})

export const showLayer = (layerGroup: ILayerGroup, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_SHOW_LAYER,
  data: {layerGroup, layer}
})

export const legendGroupHideAll = (legendGroup: ILayerGroup) => ({
  type: ActionType.LEGENDGROUP_HIDE_ALL,
  data: legendGroup
})

export const legendGroupShowAll = (legendGroup: ILayerGroup) => ({
  type: ActionType.LEGENDGROUP_SHOW_ALL,
  data: legendGroup
})

export const legendGroupReset = () => ({
  type: ActionType.LEGENDGROUP_RESET
})