import React, { useCallback, useState, useMemo } from 'react';
import { css } from 'emotion';
import { mikePalette } from '../../shared/mikeSharedTheme';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import theme from '../../shared/mikeSharedTheme'
import { ReactComponent as Up } from "@mike/mike-shared-frontend/media/icons/Up"
import { ReactComponent as Down } from "@mike/mike-shared-frontend/media/icons/Down"
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EyeVisible } from "@mike/mike-shared-frontend/media/icons/EyeVisible";
import { ReactComponent as EyeHidden } from "@mike/mike-shared-frontend/media/icons/EyeHidden";
import { useDispatch } from 'react-redux';
import { legendGroupHideAll, legendGroupShowAll } from '../../actions/legend';
import { ILayerGroup, ILegendLayer } from '../../reducers/legend';
import { IRenderElement, IThreeDRenderElement } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { Layer } from './Layer';
import { iconPrimaryStyle } from '../EditPointsForm/iconStyles';


const TitleContainerStyle = (visible: boolean) => {
  const backgroundColor = visible ? mikePalette.mediumGrey.main : mikePalette.mediumGrey.light;  
  return css`
  display: flex;
  justify-content: space-between;
  align-items: center;  
  background-color: ${backgroundColor};
  border-top: 1px solid ${mikePalette.mediumGrey.dark};
`;
}

const TitleAndButtonStyle = css`
  display: flex;
  align-items: center;
`;

export const TitleStyle = (visible: boolean) => {  
  const color = visible ? mikePalette.primary.main : mikePalette.primary.light;
  return css`
  padding-left: ${theme.spacing(1)}px;
  &.MuiTypography-root {
    color: ${color};
  }  
`;
}


interface IProps {
  layerGroup: ILayerGroup; 
}
export const LayerGroup = (props: IProps) => {
  const dispatch = useDispatch();
  const {layerGroup} = props;

  const { getState } = MikeVisualizerLib;
  const { renderedElements, rendered2DElements } = getState();

  const loadedLayers = useMemo(() => {
    const layers = layerGroup.layer;
  
    const two2DLayerIds = rendered2DElements.map((re: IRenderElement) => re.id)
    const two3DLayerIds = renderedElements.map((re: IThreeDRenderElement) => re.id)
   
    const loadedLayers = layers.filter((l: ILegendLayer) => 
      l.isTwoD ? two2DLayerIds.includes(l.id) : two3DLayerIds.includes(l.id))
    const sortedLayers = loadedLayers.sort((a: ILegendLayer, b: ILegendLayer) => {return b.order - a.order})
    return sortedLayers
  }, [layerGroup, renderedElements, rendered2DElements])

  const allLayersHidden = useMemo(() => {
    const hiddenLayers = loadedLayers.filter((l: ILegendLayer) => l.visible === false); 
    return hiddenLayers.length === loadedLayers.length
  }, [loadedLayers])
 
  const [layerGroupOpen, setLayerGroupOpen] = useState(false);

  const handleToggleVisibiltyAllLayers = useCallback(() => {
    const showAllLayers = allLayersHidden
    if (showAllLayers){
      dispatch(legendGroupShowAll(layerGroup))
    }
    else{
      dispatch(legendGroupHideAll(layerGroup))
    }
  }, [layerGroup, allLayersHidden, dispatch])

  return (     
    <div>
      <div className={TitleContainerStyle(!allLayersHidden)} key="layergroup">
        <div className={TitleAndButtonStyle}>
          {layerGroupOpen ?  <Up className={iconPrimaryStyle(allLayersHidden)} onClick={() => setLayerGroupOpen(!layerGroupOpen)} /> : 
          <Down className={iconPrimaryStyle(allLayersHidden)} onClick={() => setLayerGroupOpen(!layerGroupOpen)} />}
          <Typography className= {TitleStyle(!allLayersHidden)}  variant="body2"><b>{layerGroup.title}</b></Typography>
        </div>
        {allLayersHidden ? <EyeHidden onClick={handleToggleVisibiltyAllLayers} className={iconPrimaryStyle(true)}/> : <EyeVisible onClick={handleToggleVisibiltyAllLayers}/>}
      </div>
      {layerGroupOpen && loadedLayers.map((l: ILegendLayer, index) => <Layer key={index} layer={l} layerGroup={layerGroup}/>)}
    </div>
  );
};

export default LayerGroup