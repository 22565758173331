import React, { useCallback, useEffect, useMemo } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fastWaveConfigSet, setupGet, uploadSetup } from '../../actions/mapContent'
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { IState } from '../../reducers';
import { titleContainer, titleStyle, subTitleStyle, spinnerContainerStyle, chartContainerStyle, centerSpinnerStyle } from '../../shared/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChartContainer from '../ChartContainer/ChartContainer';
import { getJob } from '../../helpers/fastwave';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { JOBS } from '../../shared/constants';
import { ISetup } from '../../model/ISetup';
import { ReactComponent as Download } from "@mike/mike-shared-frontend/media/icons/Download"
import IconButton from '@material-ui/core/IconButton'
import UploadButton from '../UploadButton/UploadButton';
import { useIntl } from 'react-intl';
import { getHasModelResults } from '../../actions/projectContent';
import { useParams } from 'react-router-dom';
import { IListItem } from '../../reducers/mapContent';

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({
    paddingBottom: {
      paddingBottom: theme.spacing(1)
    },
    subTitle: {     
      paddingBottom: theme.spacing(1),
      color: theme.palette.primary.light,
    },  
    radioStandard: {
      padding:'5px',
      margin: '0 5px',
      fontSize: '100%'
    },
    radioColor: {     
      padding: 0,
      margin: 0,
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main
      }
    }, 
    accordeon: {
      width: theme.spacing(99)
    },
    summary: {
      display: 'flex',
      width: theme.spacing(90),
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      minHeight: theme.spacing(5)
    },
    iconButton: {
      padding: 0
    }
  })
})

const Setup = () => { 
  const classes = styles();
  const { id } = useParams();  
  const dispatch = useDispatch();
  const intl = useIntl();

  const config: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  ); 

  const loadingChartData: boolean  = useSelector(
    (state: IState) => state.mapContent.loadingChartData
  ); 

  const selectedBoundaryOrForcing: IListItem  = useSelector(
    (state: IState) => state.mapContent.selectedBoundaryOrForcing
  ); 
  
  const loadingCentralPoints: boolean  = useSelector(
    (state: IState) => state.mapContent.loadingCentralPoints
  ); 

  const setups: Array<ISetup> = useSelector(
    (state: IState) => state.mapContent.setups
  ); 

  const loadingSetups: boolean = useSelector(
    (state: IState) => state.mapContent.loadingSetups
  ); 

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  );  

  const mdaJobIsProcssing = useMemo(() => {   
    const mdaJob = getJob(jobs, JOBS.MDA) 
    return mdaJob !== undefined && JOBS_IN_PROGRESS.includes(mdaJob.status.toLowerCase())    
  }, [jobs])

  useEffect(() => {
    id && dispatch(getHasModelResults(id))
  }, [id, dispatch])

  const handleSetupChange = useCallback((event: { target: { value: React.SetStateAction<string>; }; }) => { 
    const setup = event.target.value.toString()
    const configIsUptodate = config && config.setup && config.setup === setup ? true : false
    if (!configIsUptodate){
      const updatedConfig = {...config, setup: setup }
      dispatch(fastWaveConfigSet(updatedConfig, true))
    }    
  }, [config, dispatch])



  const downloadSetup = (setup: string) => {    
    dispatch(setupGet(setup, true))
  }

  const onDropSetup = useCallback(
    (files) => {
      if (files.length > 0){
        const file = files[0]
        dispatch(uploadSetup(file, file.name))
      } 
    },[dispatch]);
  
  const setupFromConfig = useMemo(() => {
    return config.setup ? config.setup : ""
  }, [config])

  const getMissingDataUI = () => {
    return (
      <div>
        <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'setup.missingData.title'})}</Typography>
        <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'setup.missingData.subTitle'})}</Typography>
      </div>
    )
  }

  const getProcessingDataUI = () => {
    return (
      <div>
        <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'setup.processingData.title'})}</Typography>
        <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'setup.processingData.subTitle'})}</Typography>
        <div className={centerSpinnerStyle}><CircularProgress/></div>
      </div>
    )
  }

  return (
    <div>
      <div className={titleContainer}>
        <div>
          <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'setup.title'})}</Typography>
          <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'setup.subTitle'})}</Typography>
        </div>
        <UploadButton disabled={false} fileType={'.sw'} label={intl.formatMessage({id: 'desktop.upload'})} onDrop={onDropSetup}/> 
      </div>
      {loadingSetups ? <div className={spinnerContainerStyle}><CircularProgress/></div> :        
      <FormControl component="fieldset" className={classes.radioColor}>
        <RadioGroup aria-label="fileformat" name="fileformat" value={setupFromConfig} onChange={handleSetupChange}>
          {setups.map((setup, i) => {
            return (
              <Accordion key={i} className={classes.accordeon}>
                <AccordionSummary                  
                  expandIcon={!setup.isCustom && <ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.summary}>
                    <FormControlLabel value={setup.name} control={<Radio color="primary" className={classes.radioStandard} />} label={setup.displayName} /> 
                    {!setup.isCustom && 
                    <IconButton className={classes.iconButton} onClick={() => downloadSetup(setup.name)}>
                      <Download />
                    </IconButton>}
                  </div>  
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{setup.description}</Typography>
                </AccordionDetails>
              </Accordion>            
              )
            })}           
        </RadioGroup>
      </FormControl>}
      <div className={titleContainer}>
        <div>
          <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'setup.chart.legend.title'})}</Typography>
          <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'setup.chart.legend.subTitle'})}</Typography>
        </div>
      </div>  
      <div> 
        <div className={chartContainerStyle}> 
          {mdaJobIsProcssing || loadingChartData || loadingCentralPoints ? getProcessingDataUI() : !selectedBoundaryOrForcing ? getMissingDataUI() : <ChartContainer selectForTestRun={true}/>}
        </div>        
      </div>
    </div>
  )
}

export default Setup

