/* eslint-disable no-unused-vars */
enum ActionType { 
  RESET_AUTO_MESH = 'RESET_AUTO_MESH',
  LOADING_SCENARIOS = 'LOADING_SCENARIOS',
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_ROOT_CONTRIBUTOR_USERROLE = 'SET_ROOT_CONTRIBUTOR_USERROLE',
  GET_SCENARIOS = 'GET_SCENARIOS',
  SET_SCENARIOS = 'SET_SCENARIOS',
  SET_SCENARIO = 'SET_SCENARIO',
  SET_TRANFORM_DATA_LOG = 'SET_TRANFORM_DATA_LOG',
  PUBLISHING_DATA_TO_MOOD = 'PUBLISHING_DATA_TO_MOOD',
  GET_HAS_MODEL_RESULTS = 'GET_HAS_MODEL_RESULTS',
  SET_HAS_MODEL_RESULTS = 'SET_HAS_MODEL_RESULTS',
  PUBLISH_DATA_TO_MOOD = 'PUBLISH_DATA_TO_MOOD',
  ABORT_DOT_MESH_EXPORTED_CHECK = 'ABORT_DOT_MESH_EXPORTED_CHECK',
  GET_CREATE_MESH_SCENARIO_PARAMS = 'GET_CREATE_MESH_SCENARIO_PARAMS',
  CANCEL_DRAWN_GEOMETRY = 'CANCEL_DRAWN_GEOMETRY',
  SET_CREATE_MESH_PAYLOAD = 'SET_CREATE_MESH_PAYLOAD',
  SHORELINE_UPLOADED_OR_SELECTED = 'SHORELINE_UPLOADED_OR_SELECTED',
  SET_SELECTED_SHORELINE = 'SET_SELECTED_SHORELINE',
  ADD_SHORELINE = 'ADD_SHORLINE',
  POLYLINE_UPDATE = 'POLYLINE_UPDATE',
  LOADING_SHORELINE = 'LOADING_SHORELINE',
  SET_CAN_INTERPOLATE_MESH = 'SET_CAN_INTERPOLATE_MESH',
  INTERPOLATING_MESH = 'INTERPOLATING_MESH',
  GET_SHORELINE = 'GET_SHORELINE',
  GET_MESH_IS_EXPORTED = 'GET_MESH_IS_EXPORTED',
  SET_MESH_IS_EXPORTED = 'SET_MESH_IS_EXPORTED',
  CHECKING_MESH_IS_EXPORTED = 'CHECKING_MESH_IS_EXPORTED',
  GET_GEBCO = 'GET_GEBCO',
  CLEAR_AUTO_MESH = "CLEAR_AUTO_MESH",
  VTKITEM_SET = "VTKITEM_SET",
  VTK_SET = "VTK_SET", 
  OWNDATA_DOWNLOAD = 'OWNDATA/DOWNLOAD',
  OWNDATA_UPLOAD = 'OWNDATA/UPLOAD',
  OWNDATA_UPLOADED = 'OWNDATA/UPLOADED',
  OWNDATA_SHOW_DIALOG = 'OWNDATA/SHOW_DIALOG',
  OWNDATA_SET_DATATYPE = 'OWNDATA/SET_DATATYPE',
  RESET_BOUNDARY_CONDITION = 'RESET_BOUNDARY_CONDITION',
  DELETE_OUTPUT_FOLDER = 'PROJECTCONTENT/DELETE_OUTPUT_FOLDER',
  LEGENDGROUP_ADD_LAYER = 'LEGEND/LEGENDGROUP_ADD_LAYER',
  LEGENDGROUP_REMOVE_LAYERS = 'LEGEND/LEGENDGROUP_REMOVE_LAYERS',
  LEGENDGROUP_SET_LAYERS_LOADED = 'LEGEND/LEGENDGROUP_SET_LAYERS_LOADED',
  MAPCONTENT_SET_ELEVATION_NAME = 'MAPCONTENT/MAPCONTENT_SET_ELEVATION_NAME',
  LEGENDGROUP_ADD_GROUP = 'LEGEND/LEGENDGROUP_ADD_GROUP',
  LEGENDGROUP_REMOVE_GROUP = 'LEGEND/LEGENDGROUP_REMOVE_GROUP',
  LEGENDGROUP_RESET = 'LEGEND/LEGENDGROUP_RESET',
  LEGENDGROUP_HIDE_ALL = 'LEGEND/LEGENDGROUP_HIDE_ALL',
  LEGENDGROUP_SHOW_ALL = 'LEGEND/LEGENDGROUP_SHOW_ALL',
  LEGENDGROUP_DELETE_LAYER = 'LEGEND/LEGENDGROUP_DELETE_LAYER',
  LEGENDGROUP_HIDE_LAYER = 'LEGEND/LEGENDGROUP_HIDE_LAYER',
  LEGENDGROUP_HIDE_LAYER_BY_NAME = 'LEGEND/LEGENDGROUP_HIDE_LAYER_BY_NAME',
  LEGENDGROUP_SHOW_LAYER = 'LEGEND/LEGENDGROUP_SHOW_LAYER',
  UPDATE_SELECTED_BATHYS = 'CREATEMESH/UPDATE_SELECTED_BATHYS',
  BATHYS_UPLOADED_OR_SELECTED = 'CREATEMESH/BATHYS_UPLOADED_OR_SELECTED', 
  SET_BATHYMETRY_DATASETS = 'MAPCONTENT/SET_BATHYMETRY_DATASETS',
  ADD_BATHYMETRY_DATASETS = 'MAPCONTENT/ADD_BATHYMETRY_DATASETS',
  BATHYMETRY_UPLOAD = 'MAPCONTENT/BATHYMETRY_UPLOAD', 
  SET_MESH_UPLOADED = 'IMPORT/SET_MESH_UPLOADED',
  COPY_DATASETS = 'IMPORT/COPY_DATASETS',  
  COPY_DATASET = 'IMPORT/COPY_DATASET',
  COPY_COMPLETE = 'IMPORT/COPY_COMPLETE',
  COPY_FAIL = 'IMPORT/COPY_FAIL',
  REMOVE_COPY = 'IMPORT/REMOVE_COPY',
  UPDATE_SELECTED_AOIS = 'CREATEMESH/UPDATE_SELECTED_AOIS',
  UPDATE_PARAMETERS = 'CREATEMESH/UPDATE_PARAMETERS',
  DELETE_VTKITEMS = 'CREATEMESH/DELETE_VTKITEMS',
  SET_AREAS_OF_INTEREST = 'CREATEMESH/SET_AREAS_OF_INTEREST',
  ADD_AREAS_OF_INTEREST = 'CREATEMESH/ADD_AREAS_OF_INTEREST',
  AOI_UPLOAD = 'CREATEMESH/AOI_UPLOAD',
  AOIS_UPLOADED_OR_SELECTED = 'CREATEMESH/AOIS_UPLOADED_OR_SELECTED',
  DRAWN_AREA_UPLOAD = 'CREATEMESH/DRAWN_AREA_UPLOAD',
  DRAWN_AREA_UPLOADED = 'CREATEMESH/DRAWN_AREA_UPLOADED',
  GET_PROJ4STRING = 'MAPCONTENT/GET_PROJ4STRING',
  SET_PROJ4STRING= 'MAPCONTENT/SET_PROJ4STRING',
  SET_POINT_DECIMALS = 'MAPCONTENT/SET_POINT_DECIMALS',
  GET_POINT_DECIMALS = 'MAPCONTENT/GET_POINT_DECIMALS',
  SET_PREVIOUS_EXTRACTION = 'MAPCONTENT/SET_PREVIOUS_EXTRACTION',
  GET_PREVIOUS_EXTRACTIONS = 'MAPCONTENT/GET_PREVIOUS_EXTRACTIONS',
  SET_PREVIOUS_EXTRACTIONS = 'MAPCONTENT/SET_PREVIOUS_EXTRACTIONS',
  LOADING_PREVIOUS_EXTRACTIONS = 'MAPCONTENT/LOADING_PREVIOUS_EXTRACTIONS',
  DELETE_PROJECTION = 'CREATEMESH/DELETE_PROJECTION',
  CREATE_WORKSPACE = 'CREATEMESH/CREATE_WORKSPACE',
  CREATING_WORKSPACE = 'CREATEMESH/CREATING_WORKSPACE',
  CREATING_MESH = 'CREATEMESH/CREATING_MESH',
  MESHTYPE_SET = 'CREATEMESH/MESHTYPE_SET',
  CREATE_MESH = 'CREATEMESH/CREATE',
  INTERPOLATE_MESH = 'CREATEMESH/INTERPOLATE',
  PENDING_IMPORT_SET = 'CREATEMESH/PENDING_IMPORT_SET',
  DRAWN_DATA_ADD = 'MAPCONTENT/DRAWN_DATA_ADD',
  GET_MY_TENANTS = 'IAM/MY_TENANTS_GET',
  LOADING_MY_TENANTS = 'IAM/MY_TENANTS_LOADING',
  SET_MY_TENANTS = 'IAM/MY_TENANTS_SET',
  SET_USER = "SET_USER",
  SET_CONFIG = "SET_CONFIG",    
  REMOVE_PROGRESS_ITEM = 'PROGRESS/REMOVE_ITEM',
  UPDATE_OR_ADD_PROGRESS_ITEM = 'PROGRESS/UPDATE_OR_ADD_ITEM',
  PROGRESS_ITEM_COMPLETE = 'PROGRESS/ITEM_COMPLETE',
  PROGRESS_ITEM_FAILED = 'PROGRESS/ITEM_FAILED',
  HIDE_BLUE_DOT = 'NOTIFICATION/HIDE_BLUE_DOT',    
  SET_NOTIFICATION = 'NOTIFICATION/SET',
  ADD_ERROR = 'ERRORS/ADD',
  ADD_ERRORS = 'ERRORS/ADD_ERRORS',
  REMOVE_ERROR = 'ERRORS/REMOVE',
  ADD_MESSAGE = 'MESSAGE/ADD',
  REMOVE_MESSAGE = 'MESSAGE/REMOVE',
  GET_PROJECTS = 'PROJECTS/GET',
  SET_PROJECTS = 'PROJECTS/SET',
  LOADING_PROJECTS = 'PROJECTS/LOADING',
  SET_PROJECTS_ROWSPERPAGE = 'PROJECTS/SET_ROWSPERPAGE',
  SET_PROJECTS_NAMEPREFIX = 'PROJECTS/SET_NAMEPREFIX',
  SET_PROJECTS_PAGINATION = 'PROJECTS/SET_PAGINATION',
  SET_PROJECTS_SORT = 'PROJECTS/SET_SORT',
  GET_PROJECTANDPATH = 'PROJECT/GET_PROJECTANDPATH',
  SET_PROJECT = 'PROJECT/SET_PROJECT',
  LOADING_PROJECT = 'PROJECT/LOADING',
  UPDATE_ROOTPROJECT = 'PROJECTS/UPDATE_ROOTPROJECT',
  UPDATING_PROJECT = 'PROJECTS/UPDATING_PROJECT',
  DOWNLOAD_MODEL_RESULTS = 'PROJECTCONTENT/DOWNLOAD_MODEL_RESULTS',
  GET_PROJECTCONTENT = 'PROJECTCONTENT/GET',
  SET_PROJECTCONTENT = 'PROJECTCONTENT/SETPROJECTCONTENT',   
  LOADING_PROJECTCONTENT = 'PROJECTCONTENT/LOADING',
  SET_ROOTPROJECT_PANEL_OPEN = 'PROJECTS/SET_ROOTPROJECT_PANEL_OPEN',
  CREATE_ROOTPROJECT = 'PROJECTS/CREATE_ROOTPROJECT',
  CREATING_ROOTPROJECT = 'PROJECTS/CREATING_ROOTPROJECT',
  DELETE_ROOTPROJECTS = 'PROJECTCONTENT/DELETE_ROOTPROJECTS',
  DELETING_ROOTPROJECTS = 'PROJECTCONTENT/DELETING_ROOTPROJECTS',
  SET_FOLDER_PANEL_OPEN = 'PROJECTS/SET_FOLDER_PANEL_OPEN',
  CREATE_FOLDER = 'PROJECTCONTENT/CREATE_FOLDER',
  CREATING_FOLDER = 'PROJECTCONTENT/CREATING_FOLDER',
  UPDATE_FOLDER = 'PROJECTCONTENT/UPDATE_FOLDER',
  DELETE_FOLDERS = 'PROJECTCONTENT/DELETE_FOLDERS',
  DELETING_FOLDERS = 'PROJECTCONTENT/DELETING_FOLDERS',
  GET_FOLDERS = 'PROJECTCONTENT/GETFOLDERS',
  SET_FOLDERS = 'PROJECTCONTENT/SETPROJECTFOLDERS',   
  SET_FOLDER_PATH = 'PROJECTCONTENT/SET_FOLDER_PATH',  
  LOADING_FOLDERS = 'PROJECTCONTENT/LOADINGFOLDERS',
  SET_FOLDERS_PAGINATION = 'PROJECTCONTENT/SET_FOLDERS_PAGINATION',
  SET_FOLDERS_ROWSPERPAGE = 'PROJECTCONTENT/SET_FOLDERS_ROWSPERPAGE',
  SET_FOLDERS_SORT = 'PROJECTCONTENT/SET_FOLDERS_SORT',
  SET_FOLDERS_NAMEPREFIX = 'PROJECTCONTENT/SET_FOLDERS_NAMEPREFIX',
  SET_MAP_READY = 'MAPCONTENT/SET_MAP_READY',
  SET_MESH = 'MAPCONTENT/SET_MESH',
  GET_COORDINATE_SYSTEMS = 'MAPCONTENT/GET_COORDINATE_SYSTEMS',
  SET_COORDINATE_SYSTEMS = 'MAPCONTENT/SET_COORDINATE_SYSTEMS',
  LOADING_COORDINATE_SYSTEMS = 'MAPCONTENT/LOADING_COORDINATE_SYSTEMS',
  TRANSFORM_POINTS = 'MAPCONTENT/TRANSFORM_POINTS',
  POINTS_UPLOAD = 'MAPCONTENT/POINTS_UPLOAD',
  UPLOAD_POINTS_URL_SET = 'MAPCONTENT/UPLOAD_POINTS_URL_SET',
  LOAD_POINTS = 'MAPCONTENT/LOAD_POINTS',
  LOADING_POINTS = 'MAPCONTENT/LOADING_POINTS',
  UPLOADING_POINTS = 'MAPCONTENT/UPLOADING_POINTS',
  POINT_LAYER_GET = 'MAPCONTENT/POINT_LAYER_GET',
  POINT_LAYER_SET = 'MAPCONTENT/POINT_LAYER_SET',
  POINT_LAYER_INITIAL_DRAW = 'MAPCONTENT/POINT_LAYER_INITIAL_DRAW',
  LOADING_CHART_DATA = 'MAPCONTENT/LOADING_CHART_DATA',
  INITIAL_CHART_DATA_GET = 'MAPCONTENT/INITIAL_CHART_DATA_GET',
  INITIAL_CHART_DATA_SET = 'MAPCONTENT/INITIAL_CHART_DATA_SET',
  CHART_DATA_GET = 'MAPCONTENT/CHART_DATA_GET',
  CHART_DATA_SET = 'MAPCONTENT/CHART_DATA_SET',
  LOADING_EXTRACTION_INFO = 'MAPCONTENT/LOADING_EXTRACTION_INFO',
  EXTRACTION_INFO_LAYER_GET = 'MAPCONTENT/EXTRACTION_INFO_LAYER_GET',
  EXTRACTION_PERIOD_START_SET = 'MAPCONTENT/EXTRACTION_PERIOD_START_SET',
  EXTRACTION_PERIOD_END_SET = 'MAPCONTENT/EXTRACTION_PERIOD_END_SET',
  POINT_DELETE = 'MAPCONTENT/POINT_DELETE',
  POINT_UPDATE = 'MAPCONTENT/POINT_UPDATE',
  SETUP_UPLOAD = 'MAPCONTENT/SETUP_UPLOAD',
  SETUP_UPLOADED = 'MAPCONTENT/SETUP_UPLOADED',

  OUTLINE_UPLOAD = 'MAPCONTENT/OUTLINE_UPLOAD',
  LOAD_OUTLINE = 'MAPCONTENT/LOAD_OUTLINE',
  OUTLINE_UPLOADED = 'MAPCONTENT/OUTLINE_UPLOADED',
  SHOW_OUTLINE = 'MAPCONTENT/SHOW_OUTLINE',
  OUTLINE_SET = 'MAPCONTENT/OUTLINE_SET',
  OUTLINE_SET_EXTENT = 'MAPCONTENT/OUTLINE_SET_EXTENT',
  LOADING_OUTLINE = 'MAPCONTENT/LOADING_OUTLINE',
  SET_SELECTED_OUTLINE = 'MAPCONTENT/SET_SELECTED_OUTLINE',
  LOADING_AOI = 'LOADING_AOI',
  LOADING_GEBCO = 'LOADING_GEBCO',
  LOADING_BATHYMETRY = 'LOADING_BATHYMETRY',

  MESH_UPLOAD = 'MAPCONTENT/MESH_UPLOAD',
  LOAD_MESH = 'MAPCONTENT/LOAD_MESH',
  MESH_UPLOADED = 'MAPCONTENT/MESH_UPLOADED',
  SHOW_MESH = 'MAPCONTENT/SHOW_MESH',
  MESH_SET = 'MAPCONTENT/MESH_SET',
  MESH_SET_EXTENT = 'MAPCONTENT/MESH_SET_EXTENT',
  LOADING_MESH = 'MAPCONTENT/LOADING_MESH',
  BOUNDARY_LINES_SET = 'MAPCONTENT/BOUNDARY_LINES_SET',
  LOADING_DATALINK_MESH = 'MAPCONTENT/LOADING_DATALINK_MESH',
  DATALINK_MESH_GET = 'MAPCONTENT/DATALINK_MESH_GET',
  DATALINK_EXTRACTION_BUILDER_DATA_SET = 'MAPCONTENT/DATALINK_EXTRACTION_BUILDER_DATA_SET',
  DATALINK_SET_SELECTED_PROVIDER = 'MAPCONTENT/DATALINK_SET_SELECTED_PROVIDER',
  DATALINK_EXTRACTION_BUILDER_DATA_GET = 'MAPCONTENT/DATALINK_EXTRACTION_BUILDER_DATA_GET',
  DATALINK_EXTRACTION_BUILDER_DATA_LOADING = 'MAPCONTENT/DATALINK_EXTRACTION_BUILDER_DATA_LOADING',
  DATALINK_EXTRACT_DATA = 'MAPCONTENT/DATALINK_EXTRACT_DATA',
  LOADING_CENTRAL_POINTS = 'MAPCONTENT/LOADING_CENTRAL_POINTS',
  CENTRAL_POINTS_GET = 'MAPCONTENT/CENTRAL_POINTS_GET',
  CENTRAL_POINTS_SET = 'MAPCONTENT/CENTRAL_POINTS_SET',
  SELECTED_BOUNDARY_OR_FORCING_SET = 'MAPCONTENT/SELECTED_BOUNDARY_OR_FORCING_SET',
  CHART_SELECTED_BULLET_SET = 'MAPCONTENT/CHART_SELECTED_BULLET_SET',
  LOADING_CONFIG = 'MAPCONTENT/LOADING_CONFIG',
  CONFIG_LAYER_GET = 'MAPCONTENT/CONFIG_LAYER_GET',
  CONFIG_LAYER_SET = 'MAPCONTENT/CONFIG_LAYER_SET',
  LOADING_TESTRUN_OUTPUT = 'MAPCONTENT/LOADING_TESTRUN_OUTPUT',
  TESTRUN_OUTPUT_GET = 'MAPCONTENT/TESTRUN_OUTPUT_GET',
  TESTRUN_OUTPUT_SET = 'MAPCONTENT/TESTRUN_OUTPUT_SET',
  BOUNDARYCONDITION_SET = 'MAPCONTENT/BOUNDARYCONDITION_SET',
  EXTRACTION_INFO_SET = 'MAPCONTENT/EXTRACTION_INFO_SET', 
  SET_SELECTED_MESH = 'MAPCONTENT/SET_SELECTED_MESH',
  SET_MESH_IS_WITHIN_GWM_ERRORS = 'MAPCONTENT/SET_MESH_IS_WITHIN_GWM_ERRORS',
  SET_GWM = 'MAPCONTENT/SET_GWM',
  SET_DRAWING_GEOMETRYTYPE = 'MAPCONTENT/DRAWINGGEOMETRYTYPE',
  SET_EXPORT_GEOMETRY = 'MAPCONTENT/SET_EXPORT_GEOMETRY',
  SET_UPLOADED_GEOMETRY = 'MAPCONTENT/SET_UPLOADED_GEOMETRY',
  CLEAR_MAPCONTENT = 'MAPCONTENT/CLEAR',
  CLEAR_RESULTS = 'MAPCONTENT/CLEAR_RESULTS',
  CLEAR_UPLOADED_GEOMETRY = 'MAPCONTENT/CLEAR_UPLOADED_GEOMETRY',
  SHOW_TESTRUN_DIALOG = 'MAPCONTENT/SHOW_TESTRUN_DIALOG',
  SHOW_PROJECTION_SYSTEM_DIALOG = 'MAPCONTENT/SHOW_PROJECTION_SYSTEM_DIALOG',
  GET_PROJECTION_SYSTEMS_BY_ID = "MAPCONTENT/GET_PROJECTION_SYSTEMS_BY_ID",
  FILTER_PROJECTION_SYSTEMS = "MAPCONTENT/FILTER_PROJECTION_SYSTEMS",
  SHOW_SUCCESS_DIALOG = "MAPCONTENT/SHOW_SUCCESS_DIALOG",
  EVENTS_SAVE = "MAPCONTENT/EVENTS_SAVE",
  SETUPS_SET = "MAPCONTENT/SETUPS_SET",
  SETUP_GET = "MAPCONTENT/SETUP_GET",
  LOADING_SETUPS = "MAPCONTENT/LOADING_SETUPS",
  OPEN_PROJECTCONTENT_DIALOG = 'PROJECTCONTENT/DIALOG_OPEN',
  SET_PROJECTCONTENT_PAGE = 'PROJECTCONTENT/SET_PAGE',
  SET_PROJECTCONTENT_ROWSPERPAGE = 'PROJECTCONTENT/SET_ROWSPERPAGE',
  SET_PROJECTCONTENT_ORDER = 'PROJECTCONTENT/SET_ORDER',
  SET_PROJECTCONTENT_ORDERBY = 'PROJECTCONTENT/SET_ORDERBY',
  FILTER_SET = 'PROJECTCONTENT/FILTER_SET',
  GET_APPS = 'APPS/GET',
  SET_APPS = 'APPS/SET',
  LOADING_APPS = 'APPS/LOADING',
  UPDATE_FILE = 'UPDATE_FILE',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_START ='UPLOAD_START',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  UPLOAD_FAIL = 'UPLOAD_FAIL',
  UPDATE_PROGRESS = ' UPDATE_PROGRESS',
  REMOVE_UPLOAD = 'REMOVE_UPLOAD',
  UPLOAD_AND_IMPORT_FILE = 'UPLOAD_AND_IMPORT_FILE',
  IMPORT_FILE = 'IMPORTS/IMPORT_FILE',
  IMPORT_START = 'IMPORTS/IMPORT_START',
  UPDATE_IMPORT_PROGRESS = 'IMPORTS/UPDATE_PROGRESS',
  IMPORT_COMPLETE = 'IMPORTS/IMPORT_COMPLETE',
  IMPORT_FAIL = 'IMPORTS/IMPORT_FAIL',
  REMOVE_IMPORT = 'IMPORTS/REMOVE_IMPORT',
  DOWNLOAD_FILE = 'DOWNLOADS/DOWNLOAD_FILE',
  EXPORT_AND_DOWNLOAD_DATASET = 'EXPORT_AND_DOWNLOAD_DATASET',
  GET_CONTENT_OF_DATASET = 'EXPORTS/GET_CONTENT_OF_DATASET',
  EXPORT_START = 'EXPORTS/EXPORT_START',
  UPDATE_EXPORT_PROGRESS = 'EXPORTS/UPDATE_PROGRESS',
  EXPORT_COMPLETE = 'EXPORTS/EXPORT_COMPLETE',
  EXPORT_FAIL = 'EXPORTS/EXPORT_FAIL',
  REMOVE_EXPORT = 'EXPORTS/REMOVE_EXPORT',
  CREATE_JOB = "JOBS/CREATE",
  SET_JOB_STATUS = "JOBS/SET_JOB_STATUS",   
  SET_JOB = "JOBS/SET_JOB",    
  CANCEL_JOBS = "JOBS/CANCEL_JOBS",
  CANCEL_JOB = "JOBS/CANCEL_JOB",
  SET_DATA_LINK_JOB_STATUS = "DATALINKJOBS/SET_DATA_LINK_JOB_STATUS",
  SET_DATA_LINK_JOB = "DATALINKJOBS/SET_DATA_LINK_JOB",
  LOADING_JOB_CONFIG = 'JOBS/LOADING_JOB_CONFIG',
  JOBS_GET = 'JOBS/JOBS_GET',
  JOBS_SET = 'JOBS/JOBS_SET',  
  JOBS_SAVE = 'JOBS/JOBS_SAVE',
  CHECK_MESH_AND_POINTS = 'CHECK/MESH_AND_POINTS',
  CHECK_MESH_AND_POINTS_SET = 'CHECK/MESH_AND_POINTS_SET',
  CHART_AXIS_RANGE_SET = 'CHART/AXIS_RANGE_SET',
  DRAWING_SET = 'MAPCONTENT/DRAWING_SET',
  UPLOAD_DRAWNGEOMETRY = 'MAPCONTENT/UPLOAD_DRAWNGEOMETRY' 
}

export default ActionType